import { ReportType } from '../../utils/types'
import { RootState } from '../../app/store'
import { createSlice } from '@reduxjs/toolkit'

type OverviewSliceType = {
  reports: ReportType[]
  filter: string
  next?: string
}

const initialState: OverviewSliceType = {
  reports: [],
  filter: '',
  next: undefined,
}

export const reportsSlice = createSlice({
  name: 'reportsSlice',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    clearNext: (state) => {
      state.next = undefined
    },
    setReports: (state, action) => {
      state.reports = action.payload.data
    },
  },
})

export const { clearNext, setFilter, setReports } = reportsSlice.actions

export const getFilter = (state: RootState) => state.reports.filter
export const getReports = (state: RootState) => state.reports.reports
export const getNext = (state: RootState) => state.reports.next

export default reportsSlice.reducer
