import { LoadingIcon } from '@novax/zip-frontend-library'
import ReportSkeleton from 'features/previewReport/ReportSkeleton'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

export interface IPreviewReportLoadingProps {
  height: number
}

const PreviewReportLoading = ({ height }: IPreviewReportLoadingProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.loadingWrapper}>
      <ReportSkeleton height={height} />
      <div className={styles.spinnerContainer}>
        <LoadingIcon />
        <p className={styles.loadingText}>
          {t('previewReports.generatingPreview')}
        </p>
      </div>
    </div>
  )
}
export default PreviewReportLoading
