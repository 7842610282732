import { Divider, Skeleton } from 'antd'
import styled from 'styled-components'

type ReportSkeletonProps = {
  height: number
}

const StyledSkeleton = styled.div<{ height: number }>`
  height: ${(props) => `${props.height}px`};
  overflow: auto;
  background: white;
  padding: 24px;

  .header {
    display: flex;
    justify-content: space-between;

    .header-left {
      width: 202px;
    }

    .header-right {
      width: 95px;
    }
  }

  .divider {
    margin: 8px 0;
  }

  .patient {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 12px;

    .patient-section {
      display: flex;
      flex-direction: column;

      .patient-title {
        height: 12px;
        width: 98px;
        margin-bottom: 4px;
      }

      .patient-subtitle {
        height: 12px;
        width: 80px;
      }
    }
  }

  .annotation {
    display: grid;
    grid-template-columns: auto auto;
    gap: 12px;

    .annotation-section {
      display: flex;
      flex-direction: column;

      .annotation-title {
        width: 74px;
        height: 12px;
        margin-bottom: 4px;
      }

      .annotation-subtitle {
        width: 220px;
        height: 8px;
      }
    }
  }

  .main-content {
    display: flex;

    .content {
      width: 400px;
      margin-right: 13px;

      .content-section {
        margin-bottom: 18px;
      }

      .findings-title {
        width: 64px;
        height: 16px;
        margin-right: 6px;
      }
    }

    .images {
      display: grid;

      .image {
        height: 108px;
        width: 135px;
      }

      .label-section {
        width: 135px;
        height: 14px;
        margin: 4px 0 8px;
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;

        .img-tag {
          height: 14px;
          width: 16px;
          min-width: 16px;
        }

        .label {
          width: 113px;
          height: 8px;
        }
      }
    }

    .content-paragraph,
    .content-paragraph-short {
      width: 380px;
      height: 8px;
      margin-top: 5px;
      display: block;
      margin-left: 8px;

      &-short {
        width: 300px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: end;

    div {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .footer-title {
        width: 119px;
        height: 12px;
      }
      .footer-subtitle {
        width: 152px;
        height: 19px;
      } 
    }
    .footer-logo {
      width: 91px;
      height: 29px;
    }
  }
`

const ReportSkeleton = ({ height }: ReportSkeletonProps) => {
  return (
    <StyledSkeleton height={height}>
      <div className="header">
        <Skeleton.Button className="header-left" active />
        <Skeleton.Button className="header-right" active />
      </div>
      <Divider className="divider" />
      <div className="patient">
        {[...Array(8)].map((e, i) => (
          <div className="patient-section" key={i}>
            <Skeleton.Button active className="patient-title" />
            <Skeleton.Button active className="patient-subtitle" />
          </div>
        ))}
      </div>
      <Divider className="divider" />
      <div className="annotation">
        {[...Array(4)].map((e, i) => (
          <div className="annotation-section" key={i}>
            <Skeleton.Button active className="annotation-title" />
            <Skeleton.Button active className="annotation-subtitle" />
          </div>
        ))}
      </div>
      <Divider className="divider" />
      <div className="main-content">
        <div className="content">
          <div className="content-section">
            <Skeleton.Button active className="findings-title" />
            <div className="findings-section">
              {[...Array(9)].map((e, i) => (
                <Skeleton.Button key={i} active className="content-paragraph" />
              ))}
              <Skeleton.Button active className="content-paragraph-short" />
            </div>
          </div>
          <div className="content-section">
            <Skeleton.Button active className="findings-title" />
            {[...Array(9)].map((e, i) => (
              <Skeleton.Button key={i} active className="content-paragraph" />
            ))}
            <Skeleton.Button active className="content-paragraph-short" />
          </div>
          <div className="content-section">
            <Skeleton.Button active className="findings-title" />
            <Skeleton.Button active className="content-paragraph-short" />
          </div>
        </div>

        <div className="images">
          {[...Array(3)].map((e, i) => (
            <div key={i}>
              <Skeleton.Button active className="image" />
              <div className="label-section">
                <Skeleton.Button active className="img-tag" />
                <Skeleton.Button active className="label" />
              </div>
            </div>
          ))}
        </div>
      </div>

      <Divider className="divider" style={{ marginTop: '85px' }} />

      <div className="footer">
        <div>
          <Skeleton.Button active className="footer-title" />
          <Skeleton.Button active className="footer-subtitle" />
        </div>
        <div>
          <Skeleton.Button active className="footer-logo" />
        </div>
      </div>
    </StyledSkeleton>
  )
}

export default ReportSkeleton
