import axios from 'axios'
import UserService from 'services/UserService'

export const fetchPdf = async (
  reportId: string
): Promise<[Blob, string[]] | null> => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/api/reports/Preview/${reportId}.pdf`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `Bearer ${UserService.getToken()}` },
    })
    const pageTitles = response.headers['pdf-page-titles']
    const parsedPageTitles = pageTitles ? JSON.parse(pageTitles) : []
    return [response.data, parsedPageTitles]
  } catch (error) {
    console.error(error)
    return null
  }
}

export const printPdfFromBlob = (
  blob: Blob,
  renderElement: HTMLElement | null
): void => {
  if (!renderElement) return

  // remove frame if it was rendered previously
  const oldFrames = document.getElementsByName('print_iframe')
  oldFrames.forEach((el) => el.remove())

  // generate URL from blob and render it in element with display: none
  const fileURL = URL.createObjectURL(blob)
  const frame = document.createElement('iframe')
  frame.setAttribute('name', 'print_iframe')
  frame.setAttribute('src', fileURL)
  frame.setAttribute('style', 'display: none')

  // trigger native print modal on load done
  frame.addEventListener('load', () => {
    frame.contentWindow?.print()
  })

  renderElement.appendChild(frame)
}
