import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ZipModuleFeaturesPathologyResponsesPathologyDetailsDto } from 'services/zipmodule.gen'
import { findingByContainers, IContainerGroup } from 'utils/findingByContainers'
import { FindingTypes } from './enum/findingTypes'
import { IFindingForm } from './interfaces/IPathology'
import { createTerminologyTreeFromPathologyEndpoint } from './utils/apiModelMapper'
import { CombinedStatus } from 'types'
import { ITermTreeNode } from 'features/reportDetails/interfaces/IReportDetails'

const initialState = {
  findingsGroupedByContainer: {} as IContainerGroup,
  totalContainerNumbers: 0,
  totalFindingsNumbers: 0,
  data: {
    pathologyFindings: [],
    findingsSummary: undefined,
    procedureInfo: {},
  } as ZipModuleFeaturesPathologyResponsesPathologyDetailsDto,
  findingForm: {},
  findingFormInitial: {},
  findingType: FindingTypes.Polyp,
  editingItemId: '',
  findingToHighlight: '',
  procedureStatus: 4,
  isPathologyEditable: true,
  terminologyTree: {} as ITermTreeNode[] | null,
}

export const pathologySlice = createSlice({
  name: 'pathology',
  initialState,
  reducers: {
    setPathology: (
      state,
      action: PayloadAction<
        ZipModuleFeaturesPathologyResponsesPathologyDetailsDto | undefined
      >
    ) => {
      if (action.payload) {
        state.data = action.payload
        state.terminologyTree = createTerminologyTreeFromPathologyEndpoint(
          action.payload
        )
        const containers = findingByContainers(
          action.payload.findingsSummary?.containers?.components,
          createTerminologyTreeFromPathologyEndpoint(action.payload),
          'Findings',
          true,
          false,
          false,
          false
        )
        const withoutNoContainer = findingByContainers(
          action.payload.findingsSummary?.containers?.components,
          createTerminologyTreeFromPathologyEndpoint(action.payload),
          'Findings',
          false,
          false,
          false,
          false
        )
        state.findingsGroupedByContainer = containers
        state.totalContainerNumbers = Object.keys(withoutNoContainer).length
        state.totalFindingsNumbers = Object.values(containers).reduce(
          (sum, arr) => sum + arr.length,
          0
        )
        state.procedureStatus =
          action.payload.procedureInfo?.procedureStatus ??
          CombinedStatus.InProgress
      }
    },
    setFindingForm: (state, action: PayloadAction<IFindingForm>) => {
      state.findingForm = action.payload
    },
    setFindingType: (state, action: PayloadAction<FindingTypes>) => {
      state.findingType = action.payload
    },
    setFindingToHighlight: (state, action: PayloadAction<string>) => {
      state.findingToHighlight = action.payload
    },
    setEditFindingForm: (state, action: PayloadAction<Array<string>>) => {
      if (action.payload) {
        const dropdownIds = action.payload
        if (dropdownIds.length > 0) {
          // set finding type (segmentation control)
          const level1 = state.data?.pathologyFindings?.find(
            (el) => el.id == dropdownIds[0].split(',')[0]
          )
          state.editingItemId = dropdownIds[0]
          state.findingType = level1?.tKey as FindingTypes
          level1?.children?.forEach((lvl2) => {
            if (
              lvl2 &&
              lvl2.id &&
              lvl2.tKey &&
              dropdownIds.find((el) => el.split(',')[0] == lvl2.id)
            ) {
              state.findingForm = {
                ...state.findingForm,
                [lvl2?.tKey.replaceAll('-', '')]: dropdownIds.find(
                  (el) => el.split(',')[0] == lvl2.id
                ),
              }
            }
          })
          state.findingFormInitial = state.findingForm
        } else {
          state.editingItemId = ''
          state.findingForm = []
        }
      }
    },
    setIsEditItem: (state, action: PayloadAction<string>) => {
      state.editingItemId = action.payload
    },
    setProcedureStatus: (state, action: PayloadAction<number>) => {
      state.procedureStatus = action.payload
    },
    setIsPathologyEditable: (state, action: PayloadAction<boolean>) => {
      state.isPathologyEditable = action.payload
    },
  },
})

export const {
  setPathology,
  setFindingForm,
  setEditFindingForm,
  setFindingType,
  setIsEditItem,
  setFindingToHighlight,
  setProcedureStatus,
  setIsPathologyEditable,
} = pathologySlice.actions
export const getPathologyData = (state: RootState) => state.pathology.data
export const getFindingsGroupedByContainer = (state: RootState) =>
  state.pathology.findingsGroupedByContainer
export const getTotalContainerNumber = (state: RootState) =>
  state.pathology.totalContainerNumbers
export const getTotalFindingsNumbers = (state: RootState) =>
  state.pathology.totalFindingsNumbers
export const getFindingForm = (state: RootState) => state.pathology.findingForm
export const getFindingFormInitial = (state: RootState) =>
  state.pathology.findingFormInitial
export const getFindingType = (state: RootState) => state.pathology.findingType
export const getEditingItemId = (state: RootState) =>
  state.pathology.editingItemId
export const getFindingToHighlight = (state: RootState) =>
  state.pathology.findingToHighlight
export const getProcedureStatus = (state: RootState) =>
  state.pathology.procedureStatus
export const getIsPathologyEditable = (state: RootState) =>
  state.pathology.isPathologyEditable
export const getPathologyTerminologyTree = (state: RootState) =>
  state.pathology.terminologyTree

export default pathologySlice.reducer
