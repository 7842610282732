import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import {
  ZipModuleFeaturesReportsResponsesImageDto,
  ZipModuleFeaturesReportsResponsesImageUidDto,
} from 'services/zipmodule.gen'
import { IImages } from 'features/reportDetails/interfaces/IReportDetails'

const initialState: IImages = {
  allImages: [],
  selectedImages: [],
}

export const imagesSlice = createSlice({
  name: 'imagesSlice',
  initialState,
  reducers: {
    setAllImages: (state, action) => {
      state.allImages = []
      action.payload?.forEach(
        (image: ZipModuleFeaturesReportsResponsesImageDto) => {
          state.allImages.push({
            imageUid: image.imageUid,
            isSelected: image.isSelected,
          })
        }
      )
    },
    setSelectedImages: (state, action) => {
      state.selectedImages = []
      action.payload?.forEach(
        (
          image:
            | ZipModuleFeaturesReportsResponsesImageUidDto
            | ZipModuleFeaturesReportsResponsesImageDto
        ) => {
          let imageUid: ZipModuleFeaturesReportsResponsesImageUidDto | undefined
          'isSelected' in image
            ? (imageUid = image.imageUid)
            : (imageUid = <ZipModuleFeaturesReportsResponsesImageUidDto>image)
          state.selectedImages.push({
            imageUid: imageUid,
            isSelected: true,
            isInPdf: false,
            orderNumber: state.selectedImages.length + 1,
            tag: '',
          })
        }
      )
    },
  },
})
export const { setAllImages, setSelectedImages } = imagesSlice.actions

export const getAllImages = (state: RootState) => {
  return state.images.allImages
}

export const getSelectedImages = (state: RootState) => {
  return state.images.selectedImages
}

export default imagesSlice.reducer
