import { useState } from 'react'
import {
  usePostApiDebugCreateNewProcedureMutation,
  ZipModuleFeaturesDebuggingRequestsCreateNewProcedureRequest,
} from 'services/zipmodule.gen'

const CreateProcedure = () => {
  const [createProcedureApi] = usePostApiDebugCreateNewProcedureMutation()
  const [procedureObject, setProcedureObject] =
    useState<ZipModuleFeaturesDebuggingRequestsCreateNewProcedureRequest>({})

  const updateReport = () => {
    createProcedureApi({
      zipModuleFeaturesDebuggingRequestsCreateNewProcedureRequest:
        procedureObject,
    }).unwrap()
  }

  const setFormData = (
    key: keyof ZipModuleFeaturesDebuggingRequestsCreateNewProcedureRequest,
    value: string
  ) => {
    setProcedureObject((prevProcedureRequest) => ({
      ...prevProcedureRequest,
      [key]: value,
    }))
  }

  return (
    <div>
      <div style={{ padding: 10, width: '100%' }}>
        <label htmlFor="visitNumber">Visit Number</label>
        <input
          style={{ margin: 5 }}
          type="text"
          id="visitNumber"
          value={procedureObject.visitNumber ?? ''}
          onChange={(event) => setFormData('visitNumber', event.target.value)}
        />

        <label htmlFor="patientName">Patient Name</label>
        <input
          style={{ margin: 5 }}
          type="text"
          id="patientName"
          value={procedureObject.patientName ?? ''}
          onChange={(event) => setFormData('patientName', event.target.value)}
        />

        <label htmlFor="patientId">Patient ID</label>
        <input
          style={{ margin: 5 }}
          type="text"
          id="patientId"
          value={procedureObject.patientId ?? ''}
          onChange={(event) => setFormData('patientId', event.target.value)}
        />

        <label htmlFor="physicianId">Physician ID</label>
        <input
          style={{ margin: 5 }}
          type="text"
          id="physicianId"
          value={procedureObject.physicianId ?? ''}
          onChange={(event) => setFormData('physicianId', event.target.value)}
        />
      </div>
      <div style={{ padding: 10, width: '100%' }}>
        <label htmlFor="physicianName">Physician Name</label>
        <input
          style={{ margin: 5 }}
          type="text"
          id="physicianName"
          value={procedureObject.physicianName ?? ''}
          onChange={(event) => setFormData('physicianName', event.target.value)}
        />

        <label htmlFor="nurseId">Nurse ID</label>
        <input
          style={{ margin: 5 }}
          type="text"
          id="nurseId"
          value={procedureObject.nurseId ?? ''}
          onChange={(event) => setFormData('nurseId', event.target.value)}
        />

        <label htmlFor="nurseName">Nurse Name</label>
        <input
          style={{ margin: 5 }}
          type="text"
          id="nurseName"
          value={procedureObject.nurseName ?? ''}
          onChange={(event) => setFormData('nurseName', event.target.value)}
        />

        <label htmlFor="locationName">Location Name</label>
        <input
          style={{ margin: 5 }}
          type="text"
          id="locationName"
          value={procedureObject.locationName ?? ''}
          onChange={(event) => setFormData('locationName', event.target.value)}
        />
      </div>
      <div style={{ padding: 10, width: '100%' }}>
        <label htmlFor="locationDescription">Location Description</label>
        <input
          style={{ margin: 5 }}
          type="text"
          id="locationDescription"
          value={procedureObject.locationDescription ?? ''}
          onChange={(event) =>
            setFormData('locationDescription', event.target.value)
          }
        />

        <label htmlFor="dateTime">Date/Time</label>
        <input
          style={{ margin: 5 }}
          type="datetime-local"
          id="dateTime"
          value={procedureObject.dateTime ?? ''}
          onChange={(event) => setFormData('dateTime', event.target.value)}
        />

        <button onClick={updateReport}>Update Report</button>
      </div>

      <div
        style={{
          boxSizing: 'border-box',
          width: '100%',
          height: '100%',
          padding: '10px',
        }}
      >
        <p
          style={{
            fontFamily: 'Arial, sans-serif',
            fontSize: '18px',
            color: '#333',
            marginBottom: '10px',
            marginTop: '85px',
          }}
        >
          These are all identifiers for their respective FHIR Resources.
          Changing their values will result in new resources being created. For
          the Nurse, Physician, and Patient, its crucial to avoid changing these
          identifiers but leaving other details like names as they were. This
          could lead to separate resources having the same e-mails, which can
          cause unexpected behaviour when filtering the procedures by the
          logged-in user.
        </p>
        <p
          style={{
            fontFamily: 'Arial, sans-serif',
            fontSize: '16px',
            color: 'darkgray',
          }}
        >
          On the other hand, changing the VisitNumber will result in a new
          procedure being created.
          <br />
          !!!warning: Make sure the DateTime is set to the current day if you
          want to view the procedure on the Nova Report procedures screen.
        </p>
      </div>
    </div>
  )
}

export default CreateProcedure
