import { ITermTreeNode } from 'features/reportDetails/interfaces/IReportDetails'
import {
  ZipModuleFeaturesPathologyRequestsFindingDetailDto,
  ZipModuleFeaturesPathologyResponsesPathologyDetailsDto,
  ZipModuleFeaturesReportsResponsesComponentDto,
} from 'services/zipmodule.gen'
import { FindingTypes } from '../enum/findingTypes'
import { IFindingForm } from '../interfaces/IPathology'

export function addPathologyMapper(
  pathologyData: ZipModuleFeaturesPathologyResponsesPathologyDetailsDto,
  findingForm: IFindingForm,
  findingType: FindingTypes,
  editingItemId: string
) {
  const observation: ZipModuleFeaturesPathologyRequestsFindingDetailDto = {
    //hardcode terminology of Findings
    type: pathologyData.findingsSummary?.type,
    components: [
      ...(pathologyData.findingsSummary?.containers?.components ?? []),
    ],
    description: '',
  }
  const elementToChange = observation.components?.find((e) =>
    e.dropdownIds?.includes(editingItemId)
  )
  const elementToChangeIndex = elementToChange
    ? observation.components?.indexOf(elementToChange)
    : undefined
  const ids = new Array<string>()

  if (editingItemId === '') {
    //if NOT in edit mode - push level1Id and index{}
    ids.push(createLevel1Ids(pathologyData, findingType))
    fillValuesFromFindingForm(findingForm, ids, pathologyData, findingType)
  } else {
    //in edit mode, find and replace old component
    if (elementToChange?.dropdownIds) {
      //in edit mode user can not change type - polyp/general biopsy
      ids.push(elementToChange.dropdownIds[0])
      fillValuesFromFindingForm(findingForm, ids, pathologyData, findingType)
    }
  }

  // if no container selected, find "No Container" actual value and select it
  checkIfContainerExistsAndAddToIds(
    findingForm,
    ids,
    pathologyData,
    findingType
  )

  if (
    observation.components &&
    (elementToChangeIndex || elementToChangeIndex === 0)
  ) {
    observation.components[elementToChangeIndex] = {
      dropdownIds: ids,
      text: '',
      images: [],
    }
  } else {
    observation.components?.push({
      dropdownIds: ids,
      text: '',
      images: [],
    })
  }
  return observation
}

const fillValuesFromFindingForm = (
  findingForm: IFindingForm,
  dropdownIds: string[],
  pathologyData: ZipModuleFeaturesPathologyResponsesPathologyDetailsDto,
  findingType: FindingTypes
) => {
  const findingTypeTerminology = pathologyData.pathologyFindings?.find(
    (e) => e.tKey?.toLowerCase() === findingType.toLowerCase()
  )
  findingTypeTerminology?.children?.forEach((e) => {
    dropdownIds.push(e.id ?? '')
  })
  for (const [, [, value]] of Object.entries(Object.entries(findingForm))) {
    if (value && value.length > 0) {
      const el = `${value}`.split(',')[0].toString()
      const tempIndex = dropdownIds.indexOf(el)
      if (tempIndex) {
        dropdownIds[tempIndex] = value
      } else {
        dropdownIds.push(value)
      }
    }
  }
  return dropdownIds
}

const checkIfContainerExistsAndAddToIds = (
  findingForm: IFindingForm,
  ids: string[],
  pathologyData: ZipModuleFeaturesPathologyResponsesPathologyDetailsDto,
  tKey: string
) => {
  if (!findingForm.containernumber) {
    const level2Container = pathologyData?.pathologyFindings
      ?.find((el) => el.tKey == tKey)
      ?.children?.find((el) => el.tKey == 'container-number')
    const level3Container = level2Container?.children?.find(
      (el) => el.tKey == 'no-container'
    )
    if (level2Container?.id && level3Container?.id) {
      const containerLvl2Exists = ids.findIndex((el) =>
        el.includes(level2Container.id ?? '')
      )
      if (containerLvl2Exists > 0) {
        ids[containerLvl2Exists] = level2Container.id + ',' + level3Container.id
      } else {
        ids.push(level2Container.id + ',' + level3Container.id)
      }
    }
  }
}

const findBiggestIndex = (
  components: ZipModuleFeaturesReportsResponsesComponentDto[]
) => {
  let maxNumberOfSameLevel1 = 0
  components?.forEach((el) => {
    const localMax =
      el && el.dropdownIds && el.dropdownIds[0]
        ? Number(el.dropdownIds[0]?.split(',')[1])
        : 0
    if (localMax > maxNumberOfSameLevel1) {
      maxNumberOfSameLevel1 = localMax
    }
  })
  return maxNumberOfSameLevel1 + 1
}

export const createLevel1Ids = (
  pathologyData: ZipModuleFeaturesPathologyResponsesPathologyDetailsDto,
  findingType: FindingTypes,
  keepIndex?: boolean
) => {
  const level1 = pathologyData.pathologyFindings
    ? pathologyData.pathologyFindings.find((el) => el.tKey == findingType)?.id
    : null
  const index = findBiggestIndex(
    pathologyData.findingsSummary?.containers?.components ?? []
  )
  return `${level1},${keepIndex ? index - 1 : index}`
}

export const createTerminologyTreeFromPathologyEndpoint = (
  pathologyData: ZipModuleFeaturesPathologyResponsesPathologyDetailsDto
) => {
  const terminologyTree: ITermTreeNode[] = []
  terminologyTree.push({
    id: '',
    value: '',
    children: [],
    type: '',
    tKey: 'Findings',
    lvl: 0,
  })
  pathologyData.pathologyFindings?.forEach((el) => {
    terminologyTree[0].children?.push({
      id: el.id ? el.id : '',
      value: el.type ? el.type?.toString() : '',
      type: el.type ? el.type?.toString() : '',
      children: el.children as ITermTreeNode[],
      tKey: el.tKey ? el.tKey : '',
      lvl: 1,
    })
  })
  return terminologyTree
}
