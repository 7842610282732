import {
  getIsPDFLoaded,
  getIsPDFLoadError,
  getIsReportSubmitting,
} from '../../previewReportSlice'
import { useAppSelector } from '../../../../app/hooks'

import { Button } from 'antd'
import styles from './styles.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getBasicInformation } from 'features/reportDetails/reportDetailsSlice'
import { useMemo } from 'react'
import {
  ArrowLeftIcon,
  LoadingIcon,
  PrinterIcon,
  UploadIcon,
  UnorderedListIcon,
} from '@novax/zip-frontend-library'
import colors from '../../../../scss/colors.scss'
import LoadingCard from 'components/loading/LoadingCard'
import { CombinedStatus } from 'types'
import InDevelopmentTag from 'components/InDevelopmentTag'

export interface PreviewReportHeaderProps {
  onPrintClick?: () => void
  onSaveToHIS?: () => void
}

const PreviewReportHeader = ({
  onSaveToHIS,
  onPrintClick,
}: PreviewReportHeaderProps) => {
  const isPDFLoadError = useAppSelector(getIsPDFLoadError)
  const isPDFLoaded = useAppSelector(getIsPDFLoaded)
  const isReportSubmitting = useAppSelector(getIsReportSubmitting)
  const report = useAppSelector(getBasicInformation)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams() as { id: string }

  const isSavedToHIS = useMemo<boolean>(
    () => report?.combinedStatus === CombinedStatus.Submitted,
    [report]
  )

  return (
    <div className={styles.previewReportWrapper}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className={styles.backButton}
          onClick={() => navigate(`/report-details/${id}`)}
        >
          <ArrowLeftIcon style={{ color: colors.gray5 }} />
          <span>{t('previewReports.backToReport')}</span>
        </div>
        <InDevelopmentTag />
      </div>

      <div className={styles.rightSideWrapper}>
        {isPDFLoaded ? (
          <>
            <Button
              icon={
                <PrinterIcon style={{ color: 'white', fontSize: '24px' }} />
              }
              ghost
              type="ghost"
              className={styles.borderTransparent}
              disabled={
                isPDFLoadError ||
                !report ||
                ![CombinedStatus.Finished, CombinedStatus.Submitted].includes(
                  report.combinedStatus
                )
              }
              onClick={onPrintClick}
            />
            <Button
              className={styles.sideBarButton}
              onClick={onSaveToHIS}
              icon={
                isSavedToHIS ? (
                  <UnorderedListIcon />
                ) : isReportSubmitting ? (
                  <LoadingIcon />
                ) : (
                  <UploadIcon />
                )
              }
              disabled={
                isPDFLoadError ||
                !report ||
                ![CombinedStatus.Finished, CombinedStatus.Submitted].includes(
                  report.combinedStatus
                )
              }
              data-cy="preview-report-print-button"
            >
              {isSavedToHIS
                ? t('previewReports.seeProcedures')
                : isReportSubmitting
                ? t('previewReports.savingReport')
                : t('previewReports.saveReport')}
            </Button>
          </>
        ) : (
          <LoadingCard height="34px" width="122px" />
        )}
      </div>
    </div>
  )
}

export default PreviewReportHeader
