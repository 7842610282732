import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import UserService from './UserService'

// initialize an empty api service that we'll inject endpoints into later as needed
export const templateApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: async (headers) => {
      await UserService.updateToken()
      // cannot store a token via redux-persist
      // because hooks (i.e.useSelector) cannnot be called in here
      const token = UserService.getToken()
      const realm = UserService.extractRealm() as string
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
        headers.set('Nova-Tenant-Id', realm )
      }
      return headers
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }),
  endpoints: () => ({}),
})
