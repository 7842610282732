import { ITermTreeNode } from '../interfaces/IReportDetails'

export const getLevel1Value = (
  workflowStep: ITermTreeNode | undefined,
  dropdownIds: string[]
) => {
  const level1Id = dropdownIds[0].split(',')[0]
  const level1: ITermTreeNode | undefined = workflowStep?.children?.find(
    (el) => el.id == level1Id
  )
  return level1?.value
}

export const generateSentenceForComponent = (
  showLevel1: boolean,
  showContainerValue: boolean,
  workflowStep: ITermTreeNode | undefined,
  dropdownIds: string[] | null | undefined
) => {
  let level1Id: string | null = null
  let level1: ITermTreeNode | undefined = undefined
  if (dropdownIds && dropdownIds[0] && dropdownIds[0].split(',')) {
    level1Id = dropdownIds[0].split(',')[0]
    level1 = workflowStep?.children?.find((el) => el.id == level1Id)
  }

  let sentence = ''
  dropdownIds?.forEach((level: string, index: number) => {
    if (index == 0 && showLevel1) {
      const level1SentencePart = level1?.value
      sentence = level1SentencePart ? level1SentencePart : ''
    }
    const selectedLevel2Node = level1?.children?.find(
      (el) => el.id == level.split(',')[0]
    )

    const level3SentencePart = selectedLevel2Node?.children?.find(
      (el) => el.id == level.split(',')[1]
    )
    let level3SentencePartValue = level3SentencePart?.value

    if (
      (!showContainerValue && selectedLevel2Node?.tKey == 'container-number') ||
      selectedLevel2Node?.tKey == 'preservation-method'
    ) {
      level3SentencePartValue = undefined
    }
    sentence = level3SentencePartValue
      ? sentence + ', ' + level3SentencePartValue
      : sentence
  })
  return sentence.replace(/^,|,$/g, '')
}

export const generateImageTag = (
  componentIndex: string,
  componentId: string,
  level1: ITermTreeNode | undefined
) => {
  let tag = ''
  if (level1?.tKey?.toLowerCase() == 'extent of examination') {
    tag = 'EoE'
  } else if (level1?.tKey?.toLowerCase() == 'preparation') {
    tag = 'BBPS'
  } else {
    let level2Name =
      level1?.children.find((el) => el.id == componentId)?.value ?? ''
    level2Name = level2Name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .join('')
    tag = level2Name + componentIndex
    tag = tag.replace(/\s+/g, '')
  }
  return tag
}
