import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import procedureReducer from '../features/procedureOverview/procedureOverviewSlice'
import reportsReducer from '../features/reports/reportsSlice'
import reportDetailsReducer from '../features/reportDetails/reportDetailsSlice'
import imagesReducer from '../features/reportDetails/subcomponents/images/imagesSlice'
import pathologyReducer from '../features/pathology/pathologySlice'
import previewReportReducer from '../features/previewReport/previewReportSlice'
import { api } from '../services/zipmodule.gen'

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  reports: reportsReducer,
  pathology: pathologyReducer,
  previewReport: previewReportReducer,
  reportDetails: reportDetailsReducer,
  images: imagesReducer,
  procedures: procedureReducer,
})

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat(thunk, api.middleware),
})
export const setupStore = (
  serializableCheck = true,
  preloadedState?: PreloadedState<RootState>
) =>
  configureStore({
    reducer: reducers,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: serializableCheck }).concat(
        thunk,
        api.middleware
      ),
  })

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type AppStore = ReturnType<typeof setupStore>
