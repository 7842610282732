import 'react'
import {
  ZipModuleFeaturesReportsResponsesComponentDto,
  ZipModuleFeaturesReportsResponsesObservationDto,
} from 'services/zipmodule.gen'
import {
  IAddComponent,
  IObservation,
  ITermTreeNode,
} from '../interfaces/IReportDetails'
import { findingByContainers } from 'utils/findingByContainers'
import {
  CustomDescendant,
  SlateBadgeElement,
  slateToPlainText,
} from '@novax/zip-frontend-library'
import { IValueInputType } from '../subcomponents/terminologyRow/TerminologyRow'
import {
  generateBowelPreparationBadges,
  generateSlateBadgesFromIdValuePairs,
} from '../subcomponents/terminologyRow/utils/terminologyRowUtils'

export const updateObservation = (
  observationFromBackend: ZipModuleFeaturesReportsResponsesObservationDto,
  observationFrontendModel: IObservation,
  rootTerminologyNode: ITermTreeNode
): void => {
  observationFrontendModel.isSaved = observationFromBackend.isSaved ?? false

  observationFrontendModel.descriptionSlateJs = parseSlateValue(
    observationFromBackend,
    rootTerminologyNode
  )
  observationFrontendModel.description = slateToPlainText(
    observationFrontendModel.descriptionSlateJs
  )

  if (
    observationFromBackend.components &&
    observationFromBackend.components.length > 0
  ) {
    observationFrontendModel.components = observationFromBackend.components.map(
      (el: ZipModuleFeaturesReportsResponsesComponentDto) => ({
        dropdownIds: el.dropdownIds ? el.dropdownIds : [],
        text: el.text ? el.text : '',
        images: el.images ? el.images : [],
      })
    )
  } else {
    observationFrontendModel.components = []
  }
}

export const updatePathology = (
  terminologyTree: ITermTreeNode[] | null,
  observations: IObservation[]
): boolean => {
  const findings = observations.find(
    (observation: IObservation) => observation.tKey.toLowerCase() === 'findings'
  )
  const pathology = observations.find(
    (observation: IObservation) =>
      observation.tKey.toLowerCase() === 'pathology'
  )
  const containers = findingByContainers(
    findings?.components,
    terminologyTree,
    'findings',
    false,
    true,
    false,
    true
  )

  const components: ZipModuleFeaturesReportsResponsesComponentDto[] = []
  Object.entries(containers).forEach((component) => {
    component[1].forEach((el) => {
      components.push({
        dropdownIds: el.dropdownIds,
        text: el.text,
        images: el.images ? el.images : [],
      })
    })
  })
  if (pathology) {
    pathology.components = components
  }
  return components.length > 0
}

export const addComponent = (
  observationToChange: IObservation | undefined,
  payload: IAddComponent,
  component?: ZipModuleFeaturesReportsResponsesComponentDto
): void => {
  if (observationToChange) {
    // add or edit component
    if (component) {
      const index = observationToChange.components.indexOf(component)
      if (index != -1) {
        observationToChange.components[index] = {
          dropdownIds: payload.idValuePairs,
          text: payload.value
            ? payload.value
            : observationToChange.components[index].text,
          images: observationToChange.components[index].images,
        }
      }
    } else {
      observationToChange.components.push({
        dropdownIds: payload.idValuePairs,
        text: payload.value ? payload.value : '',
        images: [],
      })
    }
  }
}

/**
 * Parse SlateJS value from string.
 * If parse fails, generate input from Structured Terminology.
 * If that fails, return empty SlateJS value.
 */
const parseSlateValue = (
  observationFromBackend: ZipModuleFeaturesReportsResponsesObservationDto,
  rootTerminologyNode: ITermTreeNode
): [CustomDescendant, ...CustomDescendant[]] => {
  try {
    if (!observationFromBackend.descriptionSlateJs) throw Error()
    return JSON.parse(observationFromBackend.descriptionSlateJs)
  } catch (error) {
    if (
      // pathology (request) shouldn't be populated from terminology
      rootTerminologyNode.tKey?.toLowerCase() !== 'pathology' &&
      observationFromBackend.components &&
      observationFromBackend.components.length > 0
    ) {
      return observationFromBackend.components.map((component) => {
        //add only to rows in Medication. In the future this should be fetched from Terminology tree
        const valueInput =
          rootTerminologyNode.tKey?.toLowerCase() === 'medication'
            ? ({ type: 'number', suffix: 'mg' } as IValueInputType)
            : undefined
        return generateSlateListItemFromTerminology(
          component,
          rootTerminologyNode,
          valueInput
        )
      }) as [CustomDescendant, ...CustomDescendant[]]
    }

    return [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ]
  }
}

const generateSlateListItemFromTerminology = (
  item: ZipModuleFeaturesReportsResponsesComponentDto,
  rootNode: ITermTreeNode,
  valueInput?: IValueInputType
): CustomDescendant => {
  let badges: SlateBadgeElement[] = []
  if (!item.dropdownIds)
    return {
      type: 'paragraph',
      children: [{ text: '' }],
    }

  // Bowel Preparation has custom badge creation logic
  if (rootNode.tKey?.toLowerCase() === 'preparation') {
    badges = generateBowelPreparationBadges(rootNode, item.dropdownIds)
  } else {
    // add badge for numeric input if observation has it
    const valueBadge: SlateBadgeElement | undefined =
      valueInput && item.text
        ? {
            id: item.dropdownIds[0] + '-text',
            label: 'value',
            type: 'badge',
            children: [{ text: `${item.text} ${valueInput.suffix}` }],
          }
        : undefined

    badges = [
      ...generateSlateBadgesFromIdValuePairs(item.dropdownIds, rootNode),
      ...(valueBadge ? [valueBadge] : []),
    ]
  }
  const badgesWithText: CustomDescendant[] = [{ text: '' }]
  badges.forEach((badge, i) => {
    badgesWithText.push(
      badge,
      i !== badges.length - 1 ? { text: ', ' } : { text: ' ' }
    )
  })

  return {
    id: item.dropdownIds[0],
    type: 'list-item',
    children: [{ type: 'paragraph', children: badgesWithText }],
  }
}
