import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  marginLeft?: boolean
  autoHeight?: boolean
}
function InDevelopmentTag({ marginLeft = true, autoHeight = false }: IProps) {
  const { t } = useTranslation()

  return (
    <div
      style={{
        border: '1px solid #9DA5B7',
        padding: '2px 8px',
        fontWeight: 600,
        fontSize: '12px',
        color: '#9DA5B7',
        borderRadius: '2px',
        height: '24px',
        margin: autoHeight ? 'auto 0px' : 0,
        marginLeft: marginLeft ? '18px' : 0,
      }}
    >
      {t('common.inDevelopment')}
    </div>
  )
}

export default InDevelopmentTag
