import './App.scss'
import './i18n'
import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import colors from './scss/colors.scss'
import fontSizes from './scss/fontSizes.scss'
import fontWeights from './scss/fontWeights.scss'
import Router from './routes/routes'
import { Provider } from 'react-redux'
import { store } from './app/store'
import UserService from './services/UserService'
import { ConfigProvider, App as AntApp } from 'antd'

const AppContainer = styled.div`
  height: 100vh;
  max-height: max-content;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px;
  margin: 0px;
`

export const combinedTheme = {
  colors: colors,
  fontSizes: fontSizes,
  fontWeights: fontWeights,
}

// overrides default Ant Design styles
const antdDesignToken = {
  token: {
    colorPrimary: colors.brandPrimary6,
    colorPrimaryActive: colors.brandPrimary7,
    colorPrimaryHover: colors.brandPrimary5,
    colorPrimaryBorderHover: colors.brandPrimary5,
    colorPrimaryBorder: colors.brandPrimary3,
    colorPrimaryBgHover: colors.brandPrimary3,
    colorPrimaryBg: colors.brandPrimary1,
    colorPrimaryTextHover: colors.brandPrimary6,
    colorPrimaryTextActive: colors.brandPrimary7,

    colorInfo: colors.blue6,
    colorSuccess: colors.green6,
    colorError: colors.red6,
    colorWarning: colors.yellow6,
    fontFamily:
      "'Segoe UI', 'Noto Sans', -apple-system, BlinkMacSystemFont,'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans','Helvetica Neue', sans-serif",
    lineWidthFocus: 0,

    //make it like in antd v4
    borderRadius: 2,
    colorFillTertiary: '#f5f5f5',
  },
}

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider theme={antdDesignToken}>
        <AntApp>
          <AppContainer>
            <ThemeProvider theme={combinedTheme}>
              {UserService.isLoggedIn() ? <Router /> : <>loading...</>}
            </ThemeProvider>
          </AppContainer>
        </AntApp>
      </ConfigProvider>
    </Provider>
  )
}

export default App
