import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

type PreviewReportSliceType = {
  isPDFLoadError: boolean
  isPDFLoaded: boolean
  isReportSubmitting: boolean
  selectedPage?: number
  pageCount?: number
}

const initialState: PreviewReportSliceType = {
  isPDFLoadError: false,
  isPDFLoaded: false,
  isReportSubmitting: false,
  selectedPage: undefined,
  pageCount: undefined,
}

export const previewReportSlice = createSlice({
  name: 'previewReportSlice',
  initialState,
  reducers: {
    setIsPDFLoadError: (state, action) => {
      state.isPDFLoadError = action.payload
    },
    setIsPDFLoaded: (state, action) => {
      state.isPDFLoaded = action.payload
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload
    },
    setIsReportSubmitting: (state, action) => {
      state.isReportSubmitting = action.payload
    },
    initializePreviewReportSlice: (state, action) => {
      state.pageCount = action.payload.pageCount
      state.selectedPage = action.payload.selectedPage ?? 1
      state.isPDFLoaded = action.payload.isPDFLoaded ?? true
      state.isPDFLoadError = action.payload.isPDFLoadError ?? false
    },
  },
})

export const {
  setIsPDFLoadError,
  setIsPDFLoaded,
  setSelectedPage,
  setIsReportSubmitting,
  initializePreviewReportSlice,
} = previewReportSlice.actions
export const getIsPDFLoadError = (state: RootState) =>
  state.previewReport.isPDFLoadError
export const getIsPDFLoaded = (state: RootState) =>
  state.previewReport.isPDFLoaded
export const getSelectedPage = (state: RootState) =>
  state.previewReport.selectedPage
export const getTotalPageCount = (state: RootState) =>
  state.previewReport.pageCount
export const getIsReportSubmitting = (state: RootState) =>
  state.previewReport.isReportSubmitting

export default previewReportSlice.reducer
