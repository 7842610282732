import { ZipModuleFeaturesReportsResponsesComponentDto } from 'services/zipmodule.gen'
import { IObservation } from '../interfaces/IReportDetails'

export const updatePathologyImages = (
  componentToChange: ZipModuleFeaturesReportsResponsesComponentDto,
  observations: IObservation[]
) => {
  const pathologyObservation = observations.find(
    (observation) => observation.tKey.toLowerCase() === 'pathology'
  )
  const pathologyObservationIndex = observations.findIndex(
    (observation) => observation.tKey.toLowerCase() === 'pathology'
  )
  const pathologyComponentToChange = pathologyObservation?.components.find(
    (component) => {
      if (component.dropdownIds && componentToChange.dropdownIds)
        return component.dropdownIds[0] == componentToChange.dropdownIds[0]
    }
  )
  if (pathologyComponentToChange)
    pathologyComponentToChange.images = componentToChange.images
  return pathologyObservationIndex
}
