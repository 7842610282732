import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import {
  IAddComponent,
  IBasicInformation,
  IDrawerInfo,
  IObservation,
  IReportDetails,
  IReportsApi,
  ITermTreeNode,
} from './interfaces/IReportDetails'
import {
  addComponent,
  updateObservation,
  updatePathology,
} from './utils/manageObservations'
import { createNavigationItems } from './utils/manageNavigationItems'
import {
  ZipModuleFeaturesReportsResponsesComponentDto,
  ZipModuleFeaturesReportsResponsesImageDto,
  ZipModuleFeaturesReportsResponsesObservationDto,
} from 'services/zipmodule.gen'
import { updatePathologyImages } from './utils/updatePathologyImages'
import { generateImageTag } from './utils/sentenceGeneration'
import { updateImagesAfterSaving } from './utils/updateReportImages'
import { CombinedStatus } from 'types'
import { CustomDescendant, slateToPlainText } from '@novax/zip-frontend-library'
import _ from 'lodash'

const initialState: IReportDetails = {
  basicInformation: null,
  terminologyTree: null,
  images: [],
  observations: [],
  activeObservationId: 'basicInformation',
  reportDetailsSubmitted: false,
  navigationItems: [],
  lastSavedObservation: null,
  pathologyDataExist: false,
  observationToHighlight: null,
  reportDetailsDisabled: false,
  drawerInfo: {
    isDrawerOpen: false,
    isButtonShown: false,
  },
  selectedFinding: null,
  firstSelectedFinding: null,
  isDraggingActive: false,
  reportsApiGet: {
    isLoading: true,
    firstLoadDone: false,
    triggerRefetch: false,
    hookInstancesIds: [],
  },
}

export const reportDetailsSlice = createSlice({
  name: 'reportDetailsSlice',
  initialState,
  reducers: {
    setReportDetails: (
      state,
      action: PayloadAction<{
        basicInformation: IBasicInformation
        terminology: ITermTreeNode[]
        images: ZipModuleFeaturesReportsResponsesImageDto[]
        observations: ZipModuleFeaturesReportsResponsesObservationDto[]
      }>
    ) => {
      state.basicInformation = action.payload?.basicInformation
      state.reportDetailsDisabled =
        action.payload?.basicInformation.combinedStatus ===
        CombinedStatus.Submitted
      state.terminologyTree = action.payload?.terminology
      state.images = action.payload?.images

      const observationArray: IObservation[] = []
      state.navigationItems = []
      state.observations = []

      //first fill observations from data from the tree
      state.terminologyTree?.forEach((term: ITermTreeNode) => {
        const observationFromTerminology: IObservation = {
          type: {
            id: term.id,
            type: term.value,
          },
          description: '',
          descriptionSlateJs: [{ type: 'paragraph', children: [{ text: '' }] }],
          components: [],
          showCard: true,
          isSaved: false,
          tKey: term.tKey ?? '',
        }
        // if data coming from the backend, update observation that is prefilled from the tree
        const backendObservation = action.payload.observations.find(
          (item) => item.type?.id === term.id
        )
        backendObservation &&
          updateObservation(backendObservation, observationFromTerminology, term)
        observationArray.push(observationFromTerminology)
      })

      state.observations = observationArray

      state.pathologyDataExist = updatePathology(
        state.terminologyTree,
        state.observations
      )
      state.navigationItems = createNavigationItems(state?.terminologyTree)
      state.activeObservationId = state.lastSavedObservation
        ? state.lastSavedObservation.type.id
        : 'basicInformation'
    },
    setInitialReportDetails: (state) => {
      // eslint-disable-next-line
      state = { ...initialState }
    },
    setImages: (state, action) => {
      state.images = action.payload
    },
    setObservationText: (
      state,
      action: PayloadAction<{ id: string; value: CustomDescendant[] }>
    ) => {
      const observationToChange = state.observations.find(
        (observation: IObservation) => observation.type.id === action.payload.id
      )
      if (observationToChange) {
        const desc = action.payload.value as [
          CustomDescendant,
          ...CustomDescendant[]
        ]
        observationToChange.descriptionSlateJs = desc
        observationToChange.description = slateToPlainText(desc)
        state.lastSavedObservation = { ...observationToChange }
      }
    },
    setActiveObservationId: (state, action) => {
      const lastObservation = state.observations.find(
        (observation: IObservation) =>
          observation.type.id === state.activeObservationId
      )
      const observation = state.observations.find(
        (observation: IObservation) => observation.type.id === action.payload
      )
      if (
        lastObservation &&
        lastObservation.type.id !== observation?.type.id &&
        !lastObservation.isSaved &&
        lastObservation.descriptionSlateJs &&
        slateToPlainText(lastObservation.descriptionSlateJs).length > 0
      ) {
        lastObservation.isSaved = true
        state.lastSavedObservation = lastObservation
      } else if (
        lastObservation?.tKey.toLowerCase() === 'pathology' &&
        lastObservation.type.id !== observation?.type.id &&
        !lastObservation.isSaved
      ) {
        lastObservation.isSaved = true
        state.lastSavedObservation = lastObservation
      }

      state.activeObservationId = observation?.type.id ?? action.payload
    },
    setObservationComponent(state, action: PayloadAction<IAddComponent>) {
      const observationToChange = state.observations.find(
        (observation: IObservation) =>
          observation.type.id === action.payload.rootNode.id
      )

      let idValuePair = action.payload.idValuePairs[0]
      // if component is edited, search for the old pair of ids
      if (action.payload.isEdited) {
        idValuePair = action.payload.oldIdValuePairs[0]
      }

      const component = observationToChange?.components.find(
        (component: ZipModuleFeaturesReportsResponsesComponentDto) =>
          component &&
          component.dropdownIds &&
          component.dropdownIds[0] &&
          component.dropdownIds[0] == idValuePair
      )

      if (observationToChange) {
        if (action.payload.idValuePairs.length > 0)
          addComponent(observationToChange, action.payload, component)

        // check observation type and update Pathology if needed
        if (observationToChange.tKey.toLowerCase() === 'findings') {
          state.pathologyDataExist = updatePathology(
            state.terminologyTree,
            state.observations
          )
        }
        state.lastSavedObservation = observationToChange
      }
    },
    deleteObservationComponent: (
      state,
      action: PayloadAction<{
        idValuePairs: string[]
        rootNode: ITermTreeNode
      }>
    ) => {
      const observationToChange = state.observations.find(
        (observation: IObservation) =>
          observation.type.id === action.payload.rootNode.id
      )

      const idValuePair = action.payload.idValuePairs[0]

      const componentIndex =
        observationToChange?.components.findIndex(
          (component: ZipModuleFeaturesReportsResponsesComponentDto) =>
            component &&
            component.dropdownIds &&
            component.dropdownIds[0] &&
            component.dropdownIds[0] == idValuePair
        ) ?? -1

      if (observationToChange && componentIndex !== -1) {
        const component = observationToChange.components[componentIndex]

        //before deleting, set isSelected flag for each component image to false
        component?.images?.forEach((image) => {
          const stateImage = state.images.find((img) =>
            _.isEqual(img.imageUid, image.imageUid)
          )
          if (stateImage) {
            stateImage.isSelected = false
          }
        })

        observationToChange.components.splice(componentIndex, 1)

        // check observation type and update Pathology if needed
        if (observationToChange.tKey.toLowerCase() === 'findings') {
          state.pathologyDataExist = updatePathology(
            state.terminologyTree,
            state.observations
          )
        }
        state.lastSavedObservation = observationToChange
      }
    },
    setLastSavedObservation(state, action) {
      state.lastSavedObservation = action.payload
    },
    setReportsApiGet: (state, action) => {
      state.reportsApiGet = { ...state.reportsApiGet, ...action.payload }
      state.activeObservationId = state.lastSavedObservation
        ? state.lastSavedObservation.type.id
        : 'basicInformation'
    },
    setObservationToHighlight: (state, action) => {
      state.observationToHighlight = action.payload
    },
    registerHookInstance: (state, action) => {
      state.reportsApiGet.hookInstancesIds = [
        ...state.reportsApiGet.hookInstancesIds,
        action.payload,
      ]
    },
    unregisterHookInstance: (state, action) => {
      state.reportsApiGet.hookInstancesIds =
        state.reportsApiGet.hookInstancesIds.filter(
          (id) => id !== action.payload
        )
    },
    setDrawerInfo: (state, action: PayloadAction<IDrawerInfo>) => {
      state.drawerInfo = { ...state.drawerInfo, ...action.payload }
    },
    setSelectedFinding: (state, action) => {
      state.selectedFinding = action.payload
    },
    setFirstSelectedFinding: (state, action) => {
      state.firstSelectedFinding = action.payload
    },
    setImagesToFinding: (
      state,
      action: PayloadAction<{
        allImages: ZipModuleFeaturesReportsResponsesImageDto[]
        images: ZipModuleFeaturesReportsResponsesImageDto[]
        isDelete: boolean
      }>
    ) => {
      const observationToChange: IObservation | undefined =
        state.observations.find(
          (observation: IObservation) =>
            observation.type.id == state.activeObservationId
        )

      const componentToChange = observationToChange?.components.find(
        (component: ZipModuleFeaturesReportsResponsesComponentDto) =>
          component &&
          component.dropdownIds &&
          state.selectedFinding?.dropdownIds &&
          component.dropdownIds[0] == state.selectedFinding?.dropdownIds[0]
      )

      const componentIndex = observationToChange?.components.findIndex(
        (component: ZipModuleFeaturesReportsResponsesComponentDto) =>
          component &&
          component.dropdownIds &&
          state.selectedFinding?.dropdownIds &&
          component.dropdownIds[0] == state.selectedFinding?.dropdownIds[0]
      )

      if (
        componentToChange !== undefined &&
        observationToChange &&
        componentIndex !== undefined
      ) {
        componentToChange.images = action.payload.isDelete
          ? action.payload.images
          : [...(componentToChange.images ?? []), ...action.payload.images]
        if (
          componentToChange.dropdownIds &&
          componentToChange.dropdownIds.length > 0 &&
          !action.payload.isDelete
        ) {
          const componentIndex = componentToChange.dropdownIds[0].split(',')[1]
          const componentId = componentToChange.dropdownIds[0].split(',')[0]
          const level1 = state.terminologyTree?.find(
            (el) => el.id == observationToChange.type.id
          )
          const tag = generateImageTag(componentIndex, componentId, level1)
          // need to copy image array, since it gives readonly property object error
          const images = JSON.parse(JSON.stringify(componentToChange.images))
          images.forEach((image: ZipModuleFeaturesReportsResponsesImageDto) => {
            image.tag = tag
            image.isInPdf = true
          })
          const updatedImages = updateImagesAfterSaving(
            action.payload.allImages,
            images
          )
          state.images = updatedImages
          componentToChange.images = images
        }

        observationToChange.components[componentIndex] = componentToChange

        const pathologyObservationIndex = updatePathologyImages(
          componentToChange,
          state.observations
        )
        state.observations[pathologyObservationIndex] = {
          ...state.observations[pathologyObservationIndex],
        }
        state.lastSavedObservation = { ...observationToChange }
      }
    },
    setMoveRightLeftImages: (state, action) => {
      const observationToChange: IObservation | undefined =
        state.observations.find(
          (observation: IObservation) =>
            observation.type.id == state.activeObservationId
        )

      const componentToChange:
        | ZipModuleFeaturesReportsResponsesComponentDto
        | undefined = observationToChange?.components?.find(
        (component: ZipModuleFeaturesReportsResponsesComponentDto) => {
          if (component.dropdownIds)
            return component.dropdownIds[0] == action.payload.componentId
        }
      )

      if (componentToChange && componentToChange.images) {
        //find images to reorder
        const startImage = componentToChange.images.find(
          (el) => el.orderNumber == action.payload.originalOrderNumber
        )
        const endImage = componentToChange.images.find(
          (el) => el.orderNumber == action.payload.newOrderNumber
        )

        //reordering
        if (startImage && endImage) {
          startImage.orderNumber = action.payload.newOrderNumber
          endImage.orderNumber = action.payload.originalOrderNumber
        }

        //change images position in array
        componentToChange.images.sort((a, b) => {
          return a.orderNumber && b.orderNumber
            ? a.orderNumber - b.orderNumber
            : 0
        })
      }

      const componentIndex = observationToChange?.components?.findIndex((c) => {
        if (c.dropdownIds && componentToChange?.dropdownIds)
          return c.dropdownIds[0] === componentToChange?.dropdownIds[0]
      })

      if (componentIndex && componentToChange && observationToChange) {
        observationToChange.components[componentIndex] = componentToChange
        const pathologyObservationIndex = updatePathologyImages(
          componentToChange,
          state.observations
        )
        state.observations[pathologyObservationIndex] = {
          ...state.observations[pathologyObservationIndex],
        }
        state.lastSavedObservation = { ...observationToChange }
      }
    },
    updateImageOrder: (state, action) => {
      const observationToChange: IObservation | undefined =
        state.observations.find(
          (observation: IObservation) =>
            observation.type.id == state.activeObservationId
        )

      const componentToChange:
        | ZipModuleFeaturesReportsResponsesComponentDto
        | undefined = observationToChange?.components?.find(
        (component: ZipModuleFeaturesReportsResponsesComponentDto) => {
          if (component.dropdownIds)
            return component.dropdownIds[0] == action.payload.componentId
        }
      )

      const componentIndex = observationToChange?.components.findIndex(
        (observation) => {
          if (observation.dropdownIds)
            return observation.dropdownIds[0] === action.payload.componentId
          return
        }
      )

      const newImages = action.payload.images.map(
        (img: ZipModuleFeaturesReportsResponsesImageDto, index: number) => ({
          ...img,
          orderNumber: index + 1,
        })
      )

      if (
        componentToChange &&
        componentToChange.images &&
        observationToChange &&
        componentIndex !== undefined
      ) {
        componentToChange.images = newImages
        updatePathologyImages(componentToChange, state.observations)
        // if (state.lastSavedObservation?.type.id !== observationToChange.type.id)
        state.lastSavedObservation = { ...observationToChange }
      }
    },
    setIsDraggingActive: (state, action) => {
      state.isDraggingActive = action.payload
    },
  },
})

export const {
  setObservationText,
  setImages,
  setActiveObservationId,
  setReportDetails,
  setObservationComponent,
  setInitialReportDetails,
  setLastSavedObservation,
  setReportsApiGet,
  setObservationToHighlight,
  registerHookInstance,
  unregisterHookInstance,
  setDrawerInfo,
  setSelectedFinding,
  setFirstSelectedFinding,
  setImagesToFinding,
  setMoveRightLeftImages,
  deleteObservationComponent,
  updateImageOrder,
  setIsDraggingActive,
} = reportDetailsSlice.actions
export const getReportDetails = (state: RootState) => state.reportDetails
export const getReportDetailsDisabled = (state: RootState) =>
  state.reportDetails.reportDetailsDisabled
export const getObservations = (state: RootState) =>
  state.reportDetails.observations
export const getNavigationItems = (state: RootState) =>
  state.reportDetails.navigationItems
export const getBasicInformation = (state: RootState) =>
  state.reportDetails.basicInformation
export const getActiveObservationId = (state: RootState) =>
  state.reportDetails.activeObservationId
export const getReportDetailsSubmitted = (state: RootState) =>
  state.reportDetails.reportDetailsSubmitted
export const getTerminologyTree = (state: RootState) =>
  state.reportDetails.terminologyTree
export const getImageUids = (state: RootState) => state.reportDetails.images
export const getIsLoading = (state: RootState) =>
  state.reportDetails.reportsApiGet.isLoading
export const getLastSavedObservation = (state: RootState) =>
  state.reportDetails.lastSavedObservation
export const getPathologyDataExists = (state: RootState) =>
  state.reportDetails.pathologyDataExist
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getReportsApiGet = (state: RootState): [IReportsApi, any] => [
  state.reportDetails.reportsApiGet,
  setReportsApiGet({ triggerRefetch: true }),
]
export const getObservationToHighlight = (state: RootState) =>
  state.reportDetails.observationToHighlight
export const getReportsApiGetHookInstances = (state: RootState) =>
  state.reportDetails.reportsApiGet.hookInstancesIds
export const getDrawerInfo = (state: RootState) =>
  state.reportDetails.drawerInfo
export const getSelectedFinding = (state: RootState) =>
  state.reportDetails.selectedFinding
export const getFirstSelectedFinding = (state: RootState) =>
  state.reportDetails.firstSelectedFinding
export const getIsDraggingActive = (state: RootState) =>
  state.reportDetails.isDraggingActive

export default reportDetailsSlice.reducer
