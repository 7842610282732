import React, {
    createContext,
    useContext,
    useEffect,
    useCallback,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
    useGetApiDevicesByClientIdPairedQuery,
    ZipModuleFeaturesDevicesResponseDeviceDto,
    ZipModuleFeaturesDevicesResponseRoomDto,
} from '../../services/zipmodule.gen'
import { useClientId } from './ClientIdProvider'
import LoadingScreen from './loadingScreen/LoadingScreen'

export interface PairingProps {
    pairedDevice: ZipModuleFeaturesDevicesResponseDeviceDto
    pairedRoom: ZipModuleFeaturesDevicesResponseRoomDto
}

const PairingDataContext = createContext<PairingProps | null>(null)
const PairingDataDispatchContext = createContext<
    ((props: PairingProps | null) => void) | null
>(null)
const RemovePairingDataContext = createContext<(() => void) | null>(null)

export const usePairingData = (): PairingProps | null =>
    useContext(PairingDataContext)

export const useSetPairingData = (): ((
    props: PairingProps | null
) => void) => {
    const context = useContext(PairingDataDispatchContext)
    if (!context) {
        throw new Error(`PairingDataDispatchContext is not initialized`)
    }
    return context
}

export const useRemovePairingData = (): (() => void) => {
    const context = useContext(RemovePairingDataContext)
    if (!context) {
        throw new Error(`RemovePairingDataContext is not initialized`)
    }
    return context
}

export const setupPairingData = async (
    pairedDevice: ZipModuleFeaturesDevicesResponseDeviceDto,
    pairedRoom: ZipModuleFeaturesDevicesResponseRoomDto,
    setPairingData: (props: PairingProps) => void
): Promise<void> => {

    setPairingData({ pairedDevice, pairedRoom })

    if (pairedDevice && pairedRoom)
        localStorage.setItem('isPaired', 'true')
}

export const PairingProvider = ({
    children,
}: React.PropsWithChildren<unknown>) => {
    const { t } = useTranslation()
    const clientId = useClientId()
    const { selectedDevice, selectedRoom, isLoadingSelectedDevice } =
        useGetApiDevicesByClientIdPairedQuery(
            { clientId },
            {
                selectFromResult: ({ data, error, isLoading, isUninitialized }) => ({
                    selectedDevice: data?.device,
                    selectedRoom: data?.pairedRoom,
                    selectedDeviceError: error,
                    isLoadingSelectedDevice: isLoading || isUninitialized,
                }),
                refetchOnMountOrArgChange: true,
            }
        )

    const isDevicePaired = !!(selectedDevice && selectedRoom)
    const [connecting, setConnecting] = useState(isDevicePaired)

    const [pairingProps, setPairingProps] =
        useState<PairingProps | null>(null)

    const removePairingData = useCallback(async () => {
        if (!pairingProps) {
            return
        }
        localStorage.removeItem('isPaired')
        setPairingProps(null)
    }, [pairingProps])

    useEffect(() => {
        if (!selectedDevice || !selectedRoom) {
            return
        }

        setConnecting(true)

        const connect = async () => {
            try {
                if (selectedDevice)
                    setPairingProps({ pairedDevice: selectedDevice, pairedRoom: selectedRoom })

                localStorage.setItem('isPaired', 'true')
            } finally {
                setConnecting(false)
            }
        }
        connect()
    }, [selectedDevice, selectedRoom])

    if (isLoadingSelectedDevice) {
        return <LoadingScreen label={t('devicePairing.checkingCVConnection')} />
    }

    if (connecting) {
        return <LoadingScreen label={t('devicePairing.restoringCVConnection')} />
    }

    return (
        <PairingDataContext.Provider value={pairingProps}>
            <PairingDataDispatchContext.Provider value={setPairingProps}>
                <RemovePairingDataContext.Provider value={removePairingData}>
                    {children}
                </RemovePairingDataContext.Provider>
            </PairingDataDispatchContext.Provider>
        </PairingDataContext.Provider>
    )
}