import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { IProcedures } from './interfaces/IProcedures'
import { ZipModuleFeaturesProceduresResponsesProcedureOverviewDto } from 'services/zipmodule.gen'

const initialState: IProcedures = {
  procedures: {
    todoProcedures: [],
    todayProcedures: [],
    submittedProcedures: [],
  },
  currentlyShowingProcedures: 'active',
  finishedProceduresLabel: '',
  isLoading: true,
}

export const procedureOverviewSlice = createSlice({
  name: 'procedureOverviewSlice',
  initialState,
  reducers: {
    setTodayProcedures: (
      state,
      action: PayloadAction<{
        procedures: ZipModuleFeaturesProceduresResponsesProcedureOverviewDto[]
      }>
    ) => {
      if (action.payload) {
        state.procedures.todayProcedures = action.payload.procedures
      }
    },
    setTodoProcedures: (
      state,
      action: PayloadAction<{
        procedures: ZipModuleFeaturesProceduresResponsesProcedureOverviewDto[]
      }>
    ) => {
      if (action.payload) {
        state.procedures.todoProcedures = action.payload.procedures
      }
    },
    setSubmittedProcedures: (
      state,
      action: PayloadAction<{
        procedures: ZipModuleFeaturesProceduresResponsesProcedureOverviewDto[]
      }>
    ) => {
      if (action.payload) {
        state.procedures.submittedProcedures = action.payload.procedures
      }
    },
    setCurrentlyShowingProcedures: (
      state,
      action: PayloadAction<'active' | 'finished'>
    ) => {
      state.currentlyShowingProcedures = action.payload
    },
    setFinishedProceduresLabel: (state, action: PayloadAction<string>) => {
      state.finishedProceduresLabel = action.payload
    },
    setIsLoadingProceduresScreen: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

export const {
  setTodayProcedures,
  setTodoProcedures,
  setSubmittedProcedures,
  setCurrentlyShowingProcedures,
  setFinishedProceduresLabel,
  setIsLoadingProceduresScreen,
} = procedureOverviewSlice.actions

export const getProcedures = (state: RootState) => state.procedures.procedures
export const getCurrentlyShowingProcedures = (state: RootState) =>
  state.procedures.currentlyShowingProcedures
export const getFinishedProceduresLabel = (state: RootState) =>
  state.procedures.finishedProceduresLabel
export const getIsLoadingProceduresScreen = (state: RootState) =>
  state.procedures.isLoading

export default procedureOverviewSlice.reducer
