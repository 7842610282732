import { useEffect, useRef, useState } from 'react'
import { pdfjs } from 'react-pdf'

import useWindowDimensions from '../../utils/useWindowDimensions'
import PreviewReportSidebar from './subcomponentes/previewReportSidebar/PreviewReportSidebar'
import PreviewReportHeader from './subcomponentes/previewReportHeader/PreviewReportHeader'
import styles from './styles.module.scss'
import { fetchPdf, printPdfFromBlob } from './utils/pdfUtils'
import { useNavigate, useParams } from 'react-router-dom'
import PreviewReportLoading from './subcomponentes/previewReportLoading/PreviewReportloading'
import PreviewReportPdfView from './subcomponentes/previewReportPdfView/PreviewReportPdfView'
import { useApiSelector, useAppDispatch, useAppSelector } from 'app/hooks'
import { setIsPDFLoadError, setIsReportSubmitting } from './previewReportSlice'
import {
  getBasicInformation,
  getReportsApiGet,
} from 'features/reportDetails/reportDetailsSlice'
import { usePostApiReportsByIdSubmitMutation } from 'services/zipmodule.gen'
import useGetReportsFromApi from 'features/reportDetails/utils/useGetReportsFromApi'
import {
  NotificationType,
  notify,
} from '@novax/zip-frontend-library'
import { useTranslation } from 'react-i18next'
import { CombinedStatus } from 'types'

const PreviewReport = () => {
  const [pdfFile, setPdfFile] = useState<[Blob, string[]] | null>(null)
  const report = useAppSelector(getBasicInformation)
  const refetchReport = useApiSelector(getReportsApiGet)[1]
  const { height } = useWindowDimensions()
  const { id } = useParams() as { id: string }
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const rootRef = useRef<HTMLDivElement>(null)

  const [submitReport] = usePostApiReportsByIdSubmitMutation()
  useGetReportsFromApi(id)

  useEffect(() => {
    if (id)
      fetchPdf(id).then((pdf) => {
        if (!pdf) {
          dispatch(setIsPDFLoadError(true))
        }
        if (pdfjs) {
          pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
        }
        setPdfFile(pdf)
      })
  }, [id])

  const saveToHisOrRedirect = async () => {
    if (report) {
      if (report.combinedStatus !== CombinedStatus.Finished) {
        navigate('/procedures')
        return
      }

      try {
        dispatch(setIsReportSubmitting(true))
        await submitReport({
          id: id,
        }).unwrap()

        notify({
          type: NotificationType.SUCCESS,
          message: t('previewReports.successReportTitle'),
          description: t('previewReports.successReportDescription', {
            name: report.patientName,
          }),
        })
      } catch (error) {
        notify({
          type: NotificationType.ERROR,
          message: t('previewReports.errorReportTitle'),
          description: t('previewReports.errorReportDescription'),
        })
      } finally {
        dispatch(setIsReportSubmitting(false))
        refetchReport()
      }
    }
  }

  return (
    <div className={styles.previewReportContainer} ref={rootRef}>
      <PreviewReportHeader
        onSaveToHIS={saveToHisOrRedirect}
        onPrintClick={() =>
          printPdfFromBlob(pdfFile?.at(0) as Blob, rootRef.current)
        }
      />
      <div className={styles.previewReportContentWrapper}>
        <PreviewReportSidebar
          pdfFile={pdfFile?.at(0) as object}
          pageTitles={(pdfFile?.at(1) as string[]) ?? []}
        />
        <div className={styles.previewReportContentContainer}>
          {pdfFile ? (
            <PreviewReportPdfView
              height={height}
              pdfFile={pdfFile.at(0) as object}
            />
          ) : (
            <PreviewReportLoading height={height * 0.85} />
          )}
        </div>
      </div>
    </div>
  )
}

export default PreviewReport
