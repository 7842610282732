import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
  EqualityFn,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'
import type { RootState, AppDispatch } from './store'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useApiSelector: <RootState, Selected = unknown>(
  selector: (
    state: RootState
  ) => [Selected, ActionCreatorWithPayload<string>],
  equalityFn?: EqualityFn<Selected> | undefined
) => [Selected, () => void] = (selector) => {
  const dispatch = useDispatch<AppDispatch>()
  const selection = useSelector(selector)
  return [
    selection[0],
    () => {
      dispatch(selection[1])
    },
  ]
}
