import styles from './styles.module.scss'

interface LoadingCardProps {
  height?: string
  width?: string
  borderRadius?: string | number
}

const LoadingCard = ({ height, width, borderRadius }: LoadingCardProps) => {
  return (
    <div
      style={{
        height: height ? height : '100%',
        width: width ? width : '100%',
      }}
    >
      <div
        className={styles.loadingCard}
        style={{ borderRadius: borderRadius ?? '2px' }}
      />
    </div>
  )
}

export default LoadingCard
