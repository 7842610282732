import { useState } from 'react'
import styles from './styles.module.scss'
import colors from '../../../../scss/colors.scss'
import { Document, Page } from 'react-pdf'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  getSelectedPage,
  getTotalPageCount,
  initializePreviewReportSlice,
  setIsPDFLoadError,
  setSelectedPage,
} from 'features/previewReport/previewReportSlice'
import PreviewReportLoading from '../previewReportLoading/PreviewReportloading'
import { Button, Divider } from 'antd'
import {
  MinusIcon,
  PlusIcon,
  LeftIcon,
  RightIcon,
} from '@novax/zip-frontend-library'

export interface IPreviewReportPdfViewProps {
  height: number
  pdfFile: object
  zoomEnabled?: boolean
}

const PreviewReportPdfView = ({
  height,
  pdfFile,
  zoomEnabled,
}: IPreviewReportPdfViewProps) => {
  const [zoomRatio, setZoomRatio] = useState<number>(1)
  const totalPageCount = useAppSelector(getTotalPageCount)
  const selectedPage = useAppSelector(getSelectedPage)
  const dispatch = useAppDispatch()

  const handleSelectPage = (number: number) => {
    dispatch(setSelectedPage(number))
  }

  return (
    <>
      <Document
        className={styles.previewReportDocument}
        file={pdfFile}
        onLoadSuccess={({ numPages }: { numPages: number }) =>
          dispatch(initializePreviewReportSlice({ pageCount: numPages }))
        }
        loading={<PreviewReportLoading height={height * 0.85} />}
        onLoadError={() => {
          dispatch(setIsPDFLoadError(true))
        }}
      >
        <Page
          pageNumber={selectedPage ?? 1}
          height={height * zoomRatio}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          renderMode="svg"
        />
      </Document>
      <div className={styles.paginationWrapper}>
        <div className={styles.pagination}>
          {selectedPage && totalPageCount && (
            <>
              <div
                className={styles.icon}
                onClick={() =>
                  handleSelectPage(
                    selectedPage === 1 ? totalPageCount : selectedPage - 1
                  )
                }
              >
                <LeftIcon />
              </div>
              <div style={{ letterSpacing: '11px', marginRight: '-11px' }}>
                {`${selectedPage}/${totalPageCount}`}
              </div>
              <div
                className={styles.icon}
                onClick={() =>
                  handleSelectPage(
                    selectedPage === totalPageCount ? 1 : selectedPage + 1
                  )
                }
              >
                <RightIcon />
              </div>
            </>
          )}
          {(zoomEnabled ?? true) && (
            <>
              <Divider type="vertical" className={styles.paginationDivider} />
              <Button
                icon={<MinusIcon style={{ color: colors.white }} />}
                ghost
                type="ghost"
                className={styles.borderTransparent}
                disabled={zoomRatio < 0.7}
                onClick={() => setZoomRatio((prev) => prev - 0.1)}
              />
              <div>{`${Math.round(zoomRatio * 100)}%`}</div>
              <Button
                icon={<PlusIcon style={{ color: colors.white }} />}
                ghost
                type="ghost"
                className={styles.borderTransparent}
                disabled={zoomRatio > 2.5}
                onClick={() => setZoomRatio((prev) => prev + 0.1)}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}
export default PreviewReportPdfView
