import { ITermTreeNode } from 'features/reportDetails/interfaces/IReportDetails'
import styles from '../styles.module.scss'
import {
  SlateBadgeElement,
  SlateEditor,
  SlateText,
} from '@novax/zip-frontend-library'
import { IValueInputType } from '../TerminologyRow'
import { ZipModuleFeaturesReportsResponsesComponentDto } from 'services/zipmodule.gen'
import i18n from 'i18n'

export const filterOutHiddenTerminologyItems = (nodes: ITermTreeNode[]) =>
  nodes.filter(
    (node) =>
      !node.tKey || !['preservation-method'].includes(node.tKey.toLowerCase())
  )

export const findLabelFromNode = (
  node: ITermTreeNode | undefined,
  index: number,
  idValuePairs?: Array<string>
) => {
  let label = null
  // if level 1, first dropdown just search for selected child value
  if (index == 0) {
    label =
      idValuePairs && idValuePairs[index]
        ? node?.children.find(
            (el: ITermTreeNode) => el.id == idValuePairs[index].split(',')[0]
          )
        : null
  }
  // if level2
  else {
    // check if dropdown has value selected level2,level3 format
    idValuePairs &&
      idValuePairs.find((idValuePair) => {
        if (
          idValuePair.split(',')[0] &&
          idValuePair.split(',')[1] &&
          idValuePair.split(',')[0] == node?.id
        ) {
          label = node?.children.find(
            (options: ITermTreeNode) => options.id == idValuePair.split(',')[1]
          )
        }
      })
  }
  if (label?.value) {
    return label.value
  } else return <div className={styles.todoLabel}>{node?.value}</div>
}

export const generateSlateBadgesFromIdValuePairs = (
  idValuePairs: string[],
  rootNode: ITermTreeNode
): SlateBadgeElement[] => {
  const badges: SlateBadgeElement[] = []

  let level1Id = idValuePairs.at(0)
  // remove id addition if present
  const separatorIndex = level1Id?.lastIndexOf(',')
  level1Id = level1Id?.substring(0, separatorIndex)

  const level1Node = rootNode.children.find((node) => node.id === level1Id)
  if (!(level1Node && level1Id) || separatorIndex === -1) return badges

  // Add level1 label
  badges.push({
    id: level1Id,
    type: 'badge',
    children: [{ bold: true, id: `${level1Id}-text`, text: level1Node.value }],
  })

  // Add just level 3 nodes that are selected
  idValuePairs.slice(1).forEach((pair) => {
    const levelSeparatorIndex = pair?.indexOf(',')

    if (levelSeparatorIndex !== -1) {
      const level2Node = filterOutHiddenTerminologyItems(
        level1Node.children
      ).find((node) => node.id === pair.slice(0, levelSeparatorIndex))

      const level3Node = level2Node?.children.find(
        (node) => node.id === pair.slice(levelSeparatorIndex + 1)
      )

      level2Node &&
        level3Node &&
        badges.push({
          id: level2Node.id,
          label: level2Node.value,
          type: 'badge',
          children: [{ id: level3Node.id, text: level3Node.value }],
        })
    }
  })

  return badges
}

export const generateBowelPreparationBadges = (
  rootNode: ITermTreeNode,
  idValuePairs: string[]
): SlateBadgeElement[] => {
  let numOfFilledValues = 0
  const text: SlateText[] = []

  if (idValuePairs.length > 0)
    idValuePairs.map((id: string) => {
      if (id.indexOf(',') != -1) numOfFilledValues += 1
    })

  if (numOfFilledValues < 4) {
    text.push({ bold: true, text: i18n.t('reportDetails.missingInformation') })
  } else {
    const bowelPrepNode = rootNode.children.at(0)

    if (bowelPrepNode) {
      const scores = idValuePairs.slice(1).map((pair) => {
        const [labelId, valueId] = pair.split(',')
        const labelNode = bowelPrepNode.children.find((i) => i.id === labelId)
        const valueNode = labelNode?.children.find((i) => i.id === valueId)
        return valueNode ? parseInt(valueNode.value) : 0
      })

      const total = scores.reduce((prev, curr) => prev + curr)
      switch (true) {
        case total <= 5:
          text.push({ bold: true, text: i18n.t('bbps.poorBBPS') })
          break
        case total <= 7:
          text.push({ bold: true, text: i18n.t('bbps.goodBBPS') })
          break
        case total >= 8:
          text.push({ bold: true, text: i18n.t('bbps.excellentBBPS') })
          break
      }
      text.push({ text: total + '/9, (' + scores.join('+') + ').' })
    }
  }

  return [
    {
      id: 'bowel-prep-generated-text',
      type: 'badge',
      children: text,
    },
  ]
}

interface updateSlateEditorOnTerminologySaveUtilArgs {
  editor: SlateEditor
  prevIdValuePairs: string[]
  currentIdValuePairs: string[]
  valueInput?: IValueInputType
  value?: string
  item?: ZipModuleFeaturesReportsResponsesComponentDto
  rootNode: ITermTreeNode
}

export const updateSlateEditorOnTerminologySaveUtil = ({
  editor,
  prevIdValuePairs,
  currentIdValuePairs,
  valueInput,
  value,
  item,
  rootNode,
}: updateSlateEditorOnTerminologySaveUtilArgs) => {
  // create list item if this is new observation
  if (
    !prevIdValuePairs ||
    prevIdValuePairs.length === 0 ||
    prevIdValuePairs.at(0) !== currentIdValuePairs.at(0)
  ) {
    editor.pushNode(
      {
        id: currentIdValuePairs[0],
        type: 'list-item',
        children: [{ type: 'paragraph', children: [{ text: '' }] }],
      },
      // if first node was edited, insert new one right after it
      prevIdValuePairs.at(0) !== currentIdValuePairs.at(0)
        ? prevIdValuePairs[0]
        : undefined
    )
  }

  // delete old list-item if first node was edited
  if (
    prevIdValuePairs.at(0) &&
    prevIdValuePairs[0] !== currentIdValuePairs[0]
  ) {
    editor.removeNodeWithId(prevIdValuePairs[0])
  }

  // Bowel Preparation has custom badge creation logic
  if (rootNode.tKey?.toLowerCase() === 'preparation') {
    editor.updateBadgesinNode(
      currentIdValuePairs[0],
      generateBowelPreparationBadges(rootNode, currentIdValuePairs)
    )
    return
  }

  // update read-only badges
  // add badge for numeric input if observation has it
  const valueBadge: SlateBadgeElement | undefined =
    valueInput && (value || item?.text)
      ? {
          id: currentIdValuePairs[0] + '-text',
          label: 'value',
          type: 'badge',
          children: [
            { text: `${value ? value : item?.text} ${valueInput.suffix}` },
          ],
        }
      : undefined

  editor.updateBadgesinNode(currentIdValuePairs[0], [
    ...generateSlateBadgesFromIdValuePairs(currentIdValuePairs, rootNode),
    ...(valueBadge ? [valueBadge] : []),
  ])
}
