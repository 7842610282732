import { useAppSelector } from 'app/hooks'
import { useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import {
  ZipModuleFeaturesReportsResponsesImageDto,
  ZipModuleFeaturesReportsResponsesObservationDto,
  useGetApiReportsByIdQuery,
} from 'services/zipmodule.gen'
import {
  getReportsApiGet,
  getReportsApiGetHookInstances,
  registerHookInstance,
  setInitialReportDetails,
  setReportDetails,
  setReportsApiGet,
  unregisterHookInstance,
} from '../reportDetailsSlice'
import { IBasicInformation, ITermTreeNode } from '../interfaces/IReportDetails'

const useGetReportsFromApi = (id: string) => {
  const dispatch = useDispatch()
  const [apiObjInStore] = useAppSelector(getReportsApiGet)
  const hooksInstances = useAppSelector(getReportsApiGetHookInstances)
  // generate instance id used when multiple parents render this hook
  const instanceId = useRef<string>(new Date().toISOString())

  const thisInstanceIsActive = useMemo<boolean>(
    () => hooksInstances.length > 0 && hooksInstances[0] === instanceId.current,
    [hooksInstances]
  )

  const { data, isLoading, refetch, error } = useGetApiReportsByIdQuery(
    { id },
    {
      refetchOnMountOrArgChange: thisInstanceIsActive,
      skip: !thisInstanceIsActive,
    }
  )

  // register and unregister my id in store
  useEffect(() => {
    dispatch(registerHookInstance(instanceId.current))

    return () => {
      dispatch(unregisterHookInstance(instanceId.current))
    }
  }, [])

  //update api object in store
  useEffect(() => {
    if (thisInstanceIsActive) {
      // don't dispatch until initial api call is done
      if (apiObjInStore.firstLoadDone || !isLoading)
        dispatch(
          setReportsApiGet({
            isLoading: isLoading,
            firstLoadDone: true,
            error: error,
          })
        )

      // set apiObj in store to initial value on unmount
      return () => {
        dispatch(
          setReportsApiGet({
            isLoading: true,
            firstLoadDone: false,
            triggerRefetch: false,
            error: undefined,
          })
        )
      }
    }
  }, [isLoading, error, thisInstanceIsActive])

  // load data from API to the store
  useEffect(() => {
    if (thisInstanceIsActive && !isLoading) {
      if (!error) {
        dispatch(
          setReportDetails(
            data as unknown as {
              basicInformation: IBasicInformation
              terminology: ITermTreeNode[]
              images: ZipModuleFeaturesReportsResponsesImageDto[]
              observations: ZipModuleFeaturesReportsResponsesObservationDto[]
            }
          )
        )
      } else {
        dispatch(setInitialReportDetails())
      }
    }
  }, [data, thisInstanceIsActive])

  // do the refetch when triggered in store
  useEffect(() => {
    if (thisInstanceIsActive && apiObjInStore.triggerRefetch) {
      refetch()
      dispatch(setReportsApiGet({ triggerRefetch: false }))
    }
  }, [apiObjInStore.triggerRefetch, thisInstanceIsActive])
}

export default useGetReportsFromApi
