import {
  Navigate,
  Outlet,
  Route,
  Routes,
  BrowserRouter,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Suspense, lazy } from 'react'
import { HubConnectionContextProvider } from '../features/connect/HubConnectionProvider'
import { ClientIdProvider } from '../features/connect/ClientIdProvider'
import { PairingProvider } from 'features/connect/PairingProvider'
import PreviewReport from '../features/previewReport/PreviewReport'
import UserService from 'services/UserService'
import LoadingScreen from 'features/connect/loadingScreen/LoadingScreen'
import { Role } from 'types'
import CreateProcedure from 'features/debug/CreateProcedure'

const Pathology = lazy(() => import('features/pathology/Pathology'))
const ProcedureOverview = lazy(
  () => import('features/procedureOverview/ProcedureOverview')
)
const ReportDetails = lazy(() => import('features/reportDetails/ReportDetails'))
const DevicePairing = lazy(() => import('features/devicePairing/DevicePairing'))
const DisconnectDevicePairing = lazy(
  () => import('features/disconnectDevicePairing/DisconnectDevicePairing')
)
const DebugContainer = lazy(() => import('features/debug/DebugContainer'))
const CVSimulator = lazy(() => import('features/debug/CVSimulator'))

const PrivateRoute = ({
  role,
  children,
}: {
  role: Role
  children?: React.ReactNode
}) => {
  return (
    <>
      {UserService.getRoles().includes(role) ? children : <Navigate to="/" />}
    </>
  )
}

// navigate nurse to device pairing screen if paring is not done
const ProtectedRoute = ({ children }: { children?: React.ReactNode }) => {
  const isPaired = localStorage.getItem('isPaired')

  return (
    <>
      {(!isPaired || isPaired == 'false') &&
        UserService.getRoles().includes(Role.NURSE) ? (
        <Navigate to="/" />
      ) : (
        children
      )}
    </>
  )
}

const Router = () => {
  const { t } = useTranslation()
  const realmRoute = UserService.extractRealm() as string
  return (
    <BrowserRouter basename={realmRoute}>
      <Suspense fallback={<LoadingScreen label={t('common.loading')} />}>
        <Routes>
          <Route element={<ConnectionProviderRoute />}>
            <Route
              path="/pathology/:id"
              element={
                <PrivateRoute role={Role.NURSE}>
                  <ProtectedRoute>
                    <Pathology />
                  </ProtectedRoute>
                </PrivateRoute>
              }
            />
            <Route
              path="procedures"
              element={
                <ProtectedRoute>
                  <ProcedureOverview />
                </ProtectedRoute>
              }
            />
            <Route
              path="report-details/:id"
              element={
                <PrivateRoute role={Role.DOCTOR}>
                  <ReportDetails />
                </PrivateRoute>
              }
            />
            <Route path="/" element={<DevicePairing />} />
            <Route path="/disconnect" element={<DisconnectDevicePairing />} />
          </Route>

          <Route path="/debug" element={<DebugContainer />}>
            <Route path="cv" element={<CVSimulator />} />
            <Route path="create-procedure" element={<CreateProcedure />} />
          </Route>

            <Route path="cv" element={<CVSimulator />} />

          <Route
            path="report-details/:id"
            element={
              <PrivateRoute role={Role.DOCTOR}>
                <ReportDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/report-details/:id/preview"
            element={
              <PrivateRoute role={Role.DOCTOR}>
                <PreviewReport />
              </PrivateRoute>
            }
          />

          <Route path="/*" element={<Navigate to={realmRoute} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

const ConnectionProviderRoute = () => {
  return (
    <ClientIdProvider>
      <HubConnectionContextProvider>
        <PairingProvider>
          <Outlet />
        </PairingProvider>
      </HubConnectionContextProvider>
    </ClientIdProvider>
  )
}

export default Router
