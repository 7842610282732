import {
  getSelectedPage,
  getTotalPageCount,
  setSelectedPage,
} from '../../previewReportSlice'
import { Document, Page } from 'react-pdf'

import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import styles from './styles.module.scss'
import useWindowDimensions from '../../../../utils/useWindowDimensions'
import LoadingCard from 'components/loading/LoadingCard'

interface PreviewReportSidebarProps {
  pdfFile: object
  pageTitles: string[]
}

const PreviewReportSidebar = ({
  pdfFile,
  pageTitles,
}: PreviewReportSidebarProps) => {
  const dispatch = useAppDispatch()
  const { height } = useWindowDimensions()
  const totalPageCount = useAppSelector(getTotalPageCount)
  const selectedPage = useAppSelector(getSelectedPage)

  const handleSelectPage = (number: number) => {
    dispatch(setSelectedPage(number))
  }

  return (
    <div className={styles.sidebar}>
      {pdfFile ? (
        <Document file={pdfFile} className={styles.pagesContainer}>
          {[...Array(totalPageCount)].map((e, index) => (
            <div
              className={styles.pageWrapper}
              key={index}
              onClick={() => handleSelectPage(index + 1)}
            >
              {pageTitles.at(index) && (index === 0 || pageTitles.at(index) !== pageTitles.at(index - 1)) && (
                <span className={styles.pageTitle}>{pageTitles[index]}</span>
              )}
              <Page
                pageNumber={index + 1}
                height={height * 0.25}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                className={`${styles.page} ${index + 1 === selectedPage ? styles.selectedPage : styles.otherPage
                  }`}
              />
              <span className={styles.pageNumber}>{index + 1}</span>
            </div>
          ))}
        </Document>
      ) : (
        <div className={styles.loadingWrapper}>
          <LoadingCard height={`${height * 0.25}px`} width={`${height * 0.18}px`} />
        </div>
      )}
    </div>
  )
}

export default PreviewReportSidebar
