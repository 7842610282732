import { templateApi as api } from './template'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiMessaging: build.mutation<
      PostApiMessagingApiResponse,
      PostApiMessagingApiArg
    >({
      query: () => ({ url: `/api/messaging`, method: 'POST' }),
    }),
    getApiDebug: build.query<GetApiDebugApiResponse, GetApiDebugApiArg>({
      query: () => ({ url: `/api/debug` }),
    }),
    postApiDebugExamByServiceRequestIdSelected: build.mutation<
      PostApiDebugExamByServiceRequestIdSelectedApiResponse,
      PostApiDebugExamByServiceRequestIdSelectedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/debug/exam/${queryArg.serviceRequestId}/selected`,
        method: 'POST',
        body: queryArg.zipModuleFeaturesDebuggingExamUpdateRequest,
      }),
    }),
    postApiDebugExamByServiceRequestIdFinished: build.mutation<
      PostApiDebugExamByServiceRequestIdFinishedApiResponse,
      PostApiDebugExamByServiceRequestIdFinishedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/debug/exam/${queryArg.serviceRequestId}/finished`,
        method: 'POST',
        body: queryArg.zipModuleFeaturesDebuggingExamUpdateRequest,
      }),
    }),
    postApiDebugExamByServiceRequestIdSubmitted: build.mutation<
      PostApiDebugExamByServiceRequestIdSubmittedApiResponse,
      PostApiDebugExamByServiceRequestIdSubmittedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/debug/exam/${queryArg.serviceRequestId}/submitted`,
        method: 'POST',
        body: queryArg.zipModuleFeaturesDebuggingExamUpdateRequest,
      }),
    }),
    postApiDebugExamByServiceRequestIdReset: build.mutation<
      PostApiDebugExamByServiceRequestIdResetApiResponse,
      PostApiDebugExamByServiceRequestIdResetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/debug/exam/${queryArg.serviceRequestId}/reset`,
        method: 'POST',
        body: queryArg.zipModuleFeaturesDebuggingExamUpdateRequest,
      }),
    }),
    getApiDebugExamByServiceRequestIdImages: build.query<
      GetApiDebugExamByServiceRequestIdImagesApiResponse,
      GetApiDebugExamByServiceRequestIdImagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/debug/exam/${queryArg.serviceRequestId}/images`,
      }),
    }),
    postApiDebugExamByServiceRequestIdImages: build.mutation<
      PostApiDebugExamByServiceRequestIdImagesApiResponse,
      PostApiDebugExamByServiceRequestIdImagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/debug/exam/${queryArg.serviceRequestId}/images`,
        method: 'POST',
        body: queryArg.zipModuleFeaturesDebuggingReleaseImageRequest,
      }),
    }),
    deleteApiDebugExamByServiceRequestIdImagesAndStudyInstanceUidSeriesInstanceUidSopInstanceUid:
      build.mutation<
        DeleteApiDebugExamByServiceRequestIdImagesAndStudyInstanceUidSeriesInstanceUidSopInstanceUidApiResponse,
        DeleteApiDebugExamByServiceRequestIdImagesAndStudyInstanceUidSeriesInstanceUidSopInstanceUidApiArg
      >({
        query: (queryArg) => ({
          url: `/api/debug/exam/${queryArg.serviceRequestId}/images/${queryArg.studyInstanceUid}/${queryArg.seriesInstanceUid}/${queryArg.sopInstanceUid}`,
          method: 'DELETE',
          body: queryArg.zipModuleFeaturesDebuggingExamUpdateRequest,
        }),
      }),
    postApiDebugExamToday: build.mutation<
      PostApiDebugExamTodayApiResponse,
      PostApiDebugExamTodayApiArg
    >({
      query: () => ({ url: `/api/debug/exam/today`, method: 'POST' }),
    }),
    postApiDebugCreateNewProcedure: build.mutation<
      PostApiDebugCreateNewProcedureApiResponse,
      PostApiDebugCreateNewProcedureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/debug/createNewProcedure`,
        method: 'POST',
        body: queryArg.zipModuleFeaturesDebuggingRequestsCreateNewProcedureRequest,
      }),
    }),
    postApiDebugClearFhir: build.mutation<
      PostApiDebugClearFhirApiResponse,
      PostApiDebugClearFhirApiArg
    >({
      query: () => ({ url: `/api/debug/clearFHIR`, method: 'POST' }),
    }),
    getApiDevices: build.query<GetApiDevicesApiResponse, GetApiDevicesApiArg>({
      query: () => ({ url: `/api/devices` }),
    }),
    postApiDevicesByClientIdPaired: build.mutation<
      PostApiDevicesByClientIdPairedApiResponse,
      PostApiDevicesByClientIdPairedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices/${queryArg.clientId}/paired`,
        method: 'POST',
        body: queryArg.zipModuleFeaturesDevicesPairDeviceCommand,
      }),
    }),
    deleteApiDevicesByClientIdPaired: build.mutation<
      DeleteApiDevicesByClientIdPairedApiResponse,
      DeleteApiDevicesByClientIdPairedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices/${queryArg.clientId}/paired`,
        method: 'DELETE',
      }),
    }),
    getApiDevicesByClientIdPaired: build.query<
      GetApiDevicesByClientIdPairedApiResponse,
      GetApiDevicesByClientIdPairedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices/${queryArg.clientId}/paired`,
      }),
    }),
    getApiImagesByStudyInstanceIdAndSeriesIdInstanceId: build.query<
      GetApiImagesByStudyInstanceIdAndSeriesIdInstanceIdApiResponse,
      GetApiImagesByStudyInstanceIdAndSeriesIdInstanceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/images/${queryArg.studyInstanceId}/${queryArg.seriesId}/${queryArg.instanceId}`,
      }),
    }),
    getApiPathologyFindings: build.query<
      GetApiPathologyFindingsApiResponse,
      GetApiPathologyFindingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pathologyFindings`,
        params: { ServiceRequestId: queryArg.serviceRequestId },
      }),
    }),
    postApiPathologyFindings: build.mutation<
      PostApiPathologyFindingsApiResponse,
      PostApiPathologyFindingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pathologyFindings`,
        method: 'POST',
        body: queryArg.zipModuleFeaturesPathologyCreateFindingCommand,
      }),
    }),
    getApiProcedures: build.query<
      GetApiProceduresApiResponse,
      GetApiProceduresApiArg
    >({
      query: (queryArg) => ({
        url: `/api/procedures`,
        params: {
          ClientId: queryArg.clientId,
          PageSize: queryArg.pageSize,
          NextToken: queryArg.nextToken,
          Statuses: queryArg.statuses,
        },
      }),
    }),
    getApiReportsById: build.query<
      GetApiReportsByIdApiResponse,
      GetApiReportsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/reports/${queryArg.id}` }),
    }),
    putApiReportsById: build.mutation<
      PutApiReportsByIdApiResponse,
      PutApiReportsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reports/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.zipModuleFeaturesReportsSaveReportCommand,
      }),
    }),
    getApiReportsPreviewByIdPdf: build.query<
      GetApiReportsPreviewByIdPdfApiResponse,
      GetApiReportsPreviewByIdPdfApiArg
    >({
      query: (queryArg) => ({ url: `/api/reports/Preview/${queryArg.id}.pdf` }),
    }),
    postApiReportsByIdSubmit: build.mutation<
      PostApiReportsByIdSubmitApiResponse,
      PostApiReportsByIdSubmitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reports/${queryArg.id}/Submit`,
        method: 'POST',
      }),
    }),
    getApiReportsByIdImages: build.query<
      GetApiReportsByIdImagesApiResponse,
      GetApiReportsByIdImagesApiArg
    >({
      query: (queryArg) => ({ url: `/api/reports/${queryArg.id}/images` }),
    }),
    putApiReportsByIdImages: build.mutation<
      PutApiReportsByIdImagesApiResponse,
      PutApiReportsByIdImagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/reports/${queryArg.id}/images`,
        method: 'PUT',
        body: queryArg.zipModuleFeaturesReportsSelectImagesCommand,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type PostApiMessagingApiResponse = unknown
export type PostApiMessagingApiArg = void
export type GetApiDebugApiResponse = /** status 200 Success */ string
export type GetApiDebugApiArg = void
export type PostApiDebugExamByServiceRequestIdSelectedApiResponse = unknown
export type PostApiDebugExamByServiceRequestIdSelectedApiArg = {
  serviceRequestId: string
  zipModuleFeaturesDebuggingExamUpdateRequest: ZipModuleFeaturesDebuggingExamUpdateRequest
}
export type PostApiDebugExamByServiceRequestIdFinishedApiResponse = unknown
export type PostApiDebugExamByServiceRequestIdFinishedApiArg = {
  serviceRequestId: string
  zipModuleFeaturesDebuggingExamUpdateRequest: ZipModuleFeaturesDebuggingExamUpdateRequest
}
export type PostApiDebugExamByServiceRequestIdSubmittedApiResponse = unknown
export type PostApiDebugExamByServiceRequestIdSubmittedApiArg = {
  serviceRequestId: string
  zipModuleFeaturesDebuggingExamUpdateRequest: ZipModuleFeaturesDebuggingExamUpdateRequest
}
export type PostApiDebugExamByServiceRequestIdResetApiResponse = unknown
export type PostApiDebugExamByServiceRequestIdResetApiArg = {
  serviceRequestId: string
  zipModuleFeaturesDebuggingExamUpdateRequest: ZipModuleFeaturesDebuggingExamUpdateRequest
}
export type GetApiDebugExamByServiceRequestIdImagesApiResponse =
  /** status 200 Success */ ZipModuleFeaturesImagesImageUidsDto[]
export type GetApiDebugExamByServiceRequestIdImagesApiArg = {
  serviceRequestId: string
}
export type PostApiDebugExamByServiceRequestIdImagesApiResponse =
  /** status 200 Success */ ZipModuleFeaturesImagesImageUidsDto[]
export type PostApiDebugExamByServiceRequestIdImagesApiArg = {
  serviceRequestId: string
  zipModuleFeaturesDebuggingReleaseImageRequest: ZipModuleFeaturesDebuggingReleaseImageRequest
}
export type DeleteApiDebugExamByServiceRequestIdImagesAndStudyInstanceUidSeriesInstanceUidSopInstanceUidApiResponse =
  unknown
export type DeleteApiDebugExamByServiceRequestIdImagesAndStudyInstanceUidSeriesInstanceUidSopInstanceUidApiArg =
  {
    serviceRequestId: string
    studyInstanceUid: string
    seriesInstanceUid: string
    sopInstanceUid: string
    zipModuleFeaturesDebuggingExamUpdateRequest: ZipModuleFeaturesDebuggingExamUpdateRequest
  }
export type PostApiDebugExamTodayApiResponse = unknown
export type PostApiDebugExamTodayApiArg = void
export type PostApiDebugCreateNewProcedureApiResponse = unknown
export type PostApiDebugCreateNewProcedureApiArg = {
  zipModuleFeaturesDebuggingRequestsCreateNewProcedureRequest: ZipModuleFeaturesDebuggingRequestsCreateNewProcedureRequest
}
export type PostApiDebugClearFhirApiResponse = unknown
export type PostApiDebugClearFhirApiArg = void
export type GetApiDevicesApiResponse =
  /** status 200 Success */ ZipModuleFeaturesDevicesResponsePairingTargetsDto
export type GetApiDevicesApiArg = void
export type PostApiDevicesByClientIdPairedApiResponse = unknown
export type PostApiDevicesByClientIdPairedApiArg = {
  clientId: string
  zipModuleFeaturesDevicesPairDeviceCommand: ZipModuleFeaturesDevicesPairDeviceCommand
}
export type DeleteApiDevicesByClientIdPairedApiResponse = unknown
export type DeleteApiDevicesByClientIdPairedApiArg = {
  clientId: string
}
export type GetApiDevicesByClientIdPairedApiResponse =
  /** status 200 Success */ ZipModuleFeaturesDevicesResponsePairedDeviceDto
export type GetApiDevicesByClientIdPairedApiArg = {
  clientId: string
}
export type GetApiImagesByStudyInstanceIdAndSeriesIdInstanceIdApiResponse =
  unknown
export type GetApiImagesByStudyInstanceIdAndSeriesIdInstanceIdApiArg = {
  studyInstanceId: string
  seriesId: string
  instanceId: string
}
export type GetApiPathologyFindingsApiResponse =
  /** status 200 Success */ ZipModuleFeaturesPathologyResponsesPathologyDetailsDto
export type GetApiPathologyFindingsApiArg = {
  serviceRequestId: string
}
export type PostApiPathologyFindingsApiResponse =
  /** status 200 Success */ string
export type PostApiPathologyFindingsApiArg = {
  zipModuleFeaturesPathologyCreateFindingCommand: ZipModuleFeaturesPathologyCreateFindingCommand
}
export type GetApiProceduresApiResponse =
  /** status 200 Success */ ZipModuleCommonModelsPagingResponse601ZipModuleFeaturesProceduresResponsesProcedureOverviewDto20ZipModule20Version100020CultureNeutral20PublicKeyTokenNull
export type GetApiProceduresApiArg = {
  clientId?: string
  pageSize?: number
  nextToken?: string
  statuses?: ZipModuleCommonEnumsProcedureStatus[]
}
export type GetApiReportsByIdApiResponse =
  /** status 200 Success */ ZipModuleFeaturesReportsResponsesReportDetailsDto
export type GetApiReportsByIdApiArg = {
  id: string
}
export type PutApiReportsByIdApiResponse = /** status 200 Success */ string
export type PutApiReportsByIdApiArg = {
  id: string
  zipModuleFeaturesReportsSaveReportCommand: ZipModuleFeaturesReportsSaveReportCommand
}
export type GetApiReportsPreviewByIdPdfApiResponse = unknown
export type GetApiReportsPreviewByIdPdfApiArg = {
  id: string
}
export type PostApiReportsByIdSubmitApiResponse = unknown
export type PostApiReportsByIdSubmitApiArg = {
  id: string
}
export type GetApiReportsByIdImagesApiResponse =
  /** status 200 Success */ ZipModuleFeaturesReportsResponsesImageInformationDto[]
export type GetApiReportsByIdImagesApiArg = {
  id: string
}
export type PutApiReportsByIdImagesApiResponse =
  /** status 200 Success */ MediatRUnit
export type PutApiReportsByIdImagesApiArg = {
  /** Service request ID. */
  id: string
  /** List of selected images. (Do NOT contain unselected images.) */
  zipModuleFeaturesReportsSelectImagesCommand: ZipModuleFeaturesReportsSelectImagesCommand
}
export type ZipModuleFeaturesDebuggingExamUpdateRequest = {
  deviceId: string
  emulateRealtimeEvents: boolean
}
export type ZipModuleFeaturesImagesImageUidsDto = {
  studyInstanceUid: string
  seriesInstanceUid: string
  sopInstanceUid: string
}
export type ZipModuleFeaturesDebuggingReleaseImageRequest = {
  deviceId: string
  data: string
  emulateRealtimeEvents: boolean
}
export type ZipModuleFeaturesDebuggingRequestsCreateNewProcedureRequest = {
  visitNumber?: string | null
  patientName?: string | null
  patientId?: string | null
  physicianId?: string | null
  physicianName?: string | null
  nurseId?: string | null
  nurseName?: string | null
  locationName?: string | null
  locationDescription?: string | null
  dateTime?: string
}
export type ZipModuleFeaturesDevicesResponseDeviceDto = {
  id: string
  name: string
}
export type ZipModuleFeaturesDevicesResponseRoomDto = {
  id: string
  name: string
}
export type ZipModuleFeaturesDevicesResponsePairingTargetsDto = {
  devices?: ZipModuleFeaturesDevicesResponseDeviceDto[] | null
  rooms?: ZipModuleFeaturesDevicesResponseRoomDto[] | null
}
export type ZipModuleFeaturesDevicesPairDeviceCommand = {
  clientId: string
  deviceId: string
  roomId: string
}
export type ZipModuleFeaturesDevicesResponsePairedDeviceDto = {
  device?: ZipModuleFeaturesDevicesResponseDeviceDto
  pairedRoom?: ZipModuleFeaturesDevicesResponseRoomDto
}
export type ZipModuleFeaturesPathologyResponsesInputType = 0 | 1 | 2
export type ZipModuleFeaturesPathologyResponsesFindingOptionsDto = {
  id?: string
  value?: string | null
  tKey?: string | null
}
export type ZipModuleFeaturesPathologyResponsesTerminologyFindingDto = {
  id?: string
  label?: string | null
  inputType?: ZipModuleFeaturesPathologyResponsesInputType
  children?: ZipModuleFeaturesPathologyResponsesFindingOptionsDto[] | null
  tKey?: string | null
}
export type ZipModuleFeaturesPathologyResponsesPathologyFindingDto = {
  id?: string
  type?: string | null
  children?: ZipModuleFeaturesPathologyResponsesTerminologyFindingDto[] | null
  tKey?: string | null
}
export type ZipModuleFeaturesReportsResponsesImageUidDto = {
  studyInstanceUid: string
  seriesInstanceUid: string
  sopInstanceUid: string
}
export type ZipModuleFeaturesReportsResponsesImageDto = {
  isInPdf?: boolean
  isSelected?: boolean
  orderNumber?: number
  tag?: string | null
  imageUid?: ZipModuleFeaturesReportsResponsesImageUidDto
}
export type ZipModuleFeaturesReportsResponsesComponentDto = {
  dropdownIds?: string[] | null
  text?: string | null
  images?: ZipModuleFeaturesReportsResponsesImageDto[] | null
}
export type ZipModuleFeaturesPathologyResponsesContainerDto = {
  components?: ZipModuleFeaturesReportsResponsesComponentDto[] | null
}
export type ZipModuleFeaturesReportsResponsesObservationTypeDto = {
  id?: string | null
  type?: string | null
}
export type ZipModuleFeaturesPathologyResponsesFindingsSummaryDto = {
  containers?: ZipModuleFeaturesPathologyResponsesContainerDto
  type?: ZipModuleFeaturesReportsResponsesObservationTypeDto
}
export type ZipModuleCommonEnumsGender = 0 | 1 | 2 | 3
export type ZipModuleCommonEnumsProcedureType = 0 | 73761001
export type ZipModuleCommonEnumsProcedureStatus = 0 | 1 | 2 | 3 | 4
export type ZipModuleFeaturesPathologyResponsesProcedureInfoDto = {
  patientId?: string | null
  patientName?: string | null
  patientGender?: ZipModuleCommonEnumsGender
  patientDateOfBirth?: string
  procedureId?: string | null
  serviceRequestId?: string | null
  procedureType?: ZipModuleCommonEnumsProcedureType
  procedureDate?: string
  procedureStatus?: ZipModuleCommonEnumsProcedureStatus
}
export type ZipModuleFeaturesPathologyResponsesPathologyDetailsDto = {
  pathologyFindings?:
    | ZipModuleFeaturesPathologyResponsesPathologyFindingDto[]
    | null
  findingsSummary?: ZipModuleFeaturesPathologyResponsesFindingsSummaryDto
  procedureInfo?: ZipModuleFeaturesPathologyResponsesProcedureInfoDto
}
export type ZipModuleFeaturesPathologyRequestsFindingDetailDto = {
  type?: ZipModuleFeaturesReportsResponsesObservationTypeDto
  description?: string | null
  components?: ZipModuleFeaturesReportsResponsesComponentDto[] | null
}
export type ZipModuleFeaturesPathologyCreateFindingCommand = {
  serviceRequestId: string
  details?: ZipModuleFeaturesPathologyRequestsFindingDetailDto
}
export type ZipModuleFeaturesProceduresResponsesProcedureOverviewDto = {
  id: string
  patientId: string
  name: string
  attendingPhysicianName: string
  procedureType: ZipModuleCommonEnumsProcedureType
  status: ZipModuleCommonEnumsProcedureStatus
  date?: string | null
  birthdate: string
  totalFindingsCount?: number
  pathologyFindingsCount?: number
}
export type ZipModuleCommonModelsPaginationLinks = {
  next?: string | null
  self?: string | null
}
export type ZipModuleCommonModelsPagingResponse601ZipModuleFeaturesProceduresResponsesProcedureOverviewDto20ZipModule20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    data?: ZipModuleFeaturesProceduresResponsesProcedureOverviewDto[] | null
    links?: ZipModuleCommonModelsPaginationLinks
  }
export type ZipModuleFeaturesReportsResponsesBasicInformationDto = {
  patientId?: string | null
  patientName?: string | null
  gender?: ZipModuleCommonEnumsGender
  birthDate?: string | null
  procedureId?: string | null
  procedureType?: ZipModuleCommonEnumsProcedureType
  procedureDate?: string | null
  combinedStatus?: ZipModuleCommonEnumsProcedureStatus
  deviceId?: string | null
  procedureStartAndEndTime?: string | null
}
export type ZipModuleFeaturesReportsResponsesObservationDto = {
  type?: ZipModuleFeaturesReportsResponsesObservationTypeDto
  description?: string | null
  isSaved?: boolean
  components?: ZipModuleFeaturesReportsResponsesComponentDto[] | null
  descriptionSlateJs?: string | null
}
export type ZipModuleDomainTerminologyNode = {
  id?: string
  inputType?: ZipModuleFeaturesPathologyResponsesInputType
  type?: string | null
  value?: string | null
  category?: string[] | null
  appTag?: string[] | null
  children?: ZipModuleDomainTerminologyNode[] | null
  tKey?: string | null
  lvl?: number | null
}
export type ZipModuleFeaturesReportsResponsesReportDetailsDto = {
  basicInformation?: ZipModuleFeaturesReportsResponsesBasicInformationDto
  images?: ZipModuleFeaturesReportsResponsesImageDto[] | null
  observations?: ZipModuleFeaturesReportsResponsesObservationDto[] | null
  terminology?: ZipModuleDomainTerminologyNode[] | null
}
export type ZipModuleFeaturesReportsSaveReportCommand = {
  serviceRequestId: string
  reportDetails: ZipModuleFeaturesReportsResponsesReportDetailsDto
}
export type ZipModuleFeaturesReportsResponsesImageInformationDto = {
  imageUid: ZipModuleFeaturesReportsResponsesImageUidDto
  isSelected: boolean
}
export type MediatRUnit = object
export type ZipModuleFeaturesReportsRequestsImageUidDto = {
  studyInstanceUid: string
  seriesInstanceUid: string
  sopInstanceUid: string
}
export type ZipModuleFeaturesReportsRequestsImageInformationDto = {
  imageUid: ZipModuleFeaturesReportsRequestsImageUidDto
  isSelected: boolean
}
export type ZipModuleFeaturesReportsSelectImagesCommand = {
  serviceRequestId: string
  images: ZipModuleFeaturesReportsRequestsImageInformationDto[]
}
export const {
  usePostApiMessagingMutation,
  useGetApiDebugQuery,
  usePostApiDebugExamByServiceRequestIdSelectedMutation,
  usePostApiDebugExamByServiceRequestIdFinishedMutation,
  usePostApiDebugExamByServiceRequestIdSubmittedMutation,
  usePostApiDebugExamByServiceRequestIdResetMutation,
  useGetApiDebugExamByServiceRequestIdImagesQuery,
  usePostApiDebugExamByServiceRequestIdImagesMutation,
  useDeleteApiDebugExamByServiceRequestIdImagesAndStudyInstanceUidSeriesInstanceUidSopInstanceUidMutation,
  usePostApiDebugExamTodayMutation,
  usePostApiDebugCreateNewProcedureMutation,
  usePostApiDebugClearFhirMutation,
  useGetApiDevicesQuery,
  usePostApiDevicesByClientIdPairedMutation,
  useDeleteApiDevicesByClientIdPairedMutation,
  useGetApiDevicesByClientIdPairedQuery,
  useGetApiImagesByStudyInstanceIdAndSeriesIdInstanceIdQuery,
  useGetApiPathologyFindingsQuery,
  usePostApiPathologyFindingsMutation,
  useGetApiProceduresQuery,
  useGetApiReportsByIdQuery,
  usePutApiReportsByIdMutation,
  useGetApiReportsPreviewByIdPdfQuery,
  usePostApiReportsByIdSubmitMutation,
  useGetApiReportsByIdImagesQuery,
  usePutApiReportsByIdImagesMutation,
} = injectedRtkApi
