import { v4 as uuidv4 } from 'uuid'
import React, { createContext, useContext } from 'react'

const ClientIdContext = createContext<string | null>(null)

export const useClientId = (): string => {
  const token = useContext(ClientIdContext)
  if (!token) {
    throw new Error(`useClientId() in available only in <ClientIdProvider>`)
  }
  return token
}

const getOrCreateToken = () => {
  // TODO use device-unique id

  const token = localStorage.getItem('token')
  if (token) {
    return token
  }

  const newToken = uuidv4()
  localStorage.setItem('token', newToken)
  return newToken
}

export const ClientIdProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const token = getOrCreateToken()
  return (
    <ClientIdContext.Provider value={token}>
      {children}
    </ClientIdContext.Provider>
  )
}
