import _ from 'lodash'
import {
  ZipModuleFeaturesReportsResponsesComponentDto,
  ZipModuleFeaturesReportsResponsesImageDto,
} from 'services/zipmodule.gen'

export const updateOrderNumbers = (
  selectedFinding: ZipModuleFeaturesReportsResponsesComponentDto,
  selectedImages: ZipModuleFeaturesReportsResponsesImageDto[]
) => {
  let maxOrderNumber = 0
  if (selectedFinding?.images)
    maxOrderNumber = Math.max(
      ...selectedFinding.images.map((image) => image.orderNumber ?? 0),
      0
    )

  const updatedImages: ZipModuleFeaturesReportsResponsesImageDto[] = []
  selectedImages.forEach((image) => {
    if (image.orderNumber) {
      updatedImages.push({
        ...image,
        orderNumber: maxOrderNumber + image.orderNumber,
      })
    }
  })
  return updatedImages
}

export const updateImagesAfterSaving = (
  allImages: ZipModuleFeaturesReportsResponsesImageDto[],
  selectedImages: ZipModuleFeaturesReportsResponsesImageDto[]
) => {
  const updatedImages = allImages.map((image) => {
    const foundImage: ZipModuleFeaturesReportsResponsesImageDto | undefined =
      selectedImages.find(
        (selectedImage: ZipModuleFeaturesReportsResponsesImageDto) =>
          _.isEqual(selectedImage.imageUid, image.imageUid)
      )
    return foundImage
      ? {
          ...foundImage,
        }
      : { ...image }
  })

  return updatedImages
}

export const updateImagesAfterDeleting = (
  allImages: ZipModuleFeaturesReportsResponsesImageDto[],
  deletedImage: ZipModuleFeaturesReportsResponsesImageDto
) => {
  const updatedImages = allImages.map((image) => {
    return _.isEqual(deletedImage.imageUid, image.imageUid)
      ? { ...image, isSelected: false, tag: '', isInPdf: false }
      : { ...image }
  })

  return updatedImages
}
