import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import UserService from 'services/UserService'

export interface HubConnectionProps {
  connection: HubConnection
}

const HubConnectionContext = createContext<HubConnectionProps | null>(null)
const HubConnectionDispatchContext = createContext<
  ((props: HubConnectionProps | null) => void) | null
>(null)
const DisconnectHubConnectionContext = createContext<(() => void) | null>(null)

export const useHubConnection = (): HubConnectionProps | null =>
  useContext(HubConnectionContext)

export const useSetHubConnection = (): ((
  props: HubConnectionProps | null
) => void) => {
  const context = useContext(HubConnectionDispatchContext)
  if (!context) {
    throw new Error(`HubConnectionDispatchContext is not initialized`)
  }
  return context
}

export const useDisconnectHubConnection = (): (() => void) => {
  const context = useContext(DisconnectHubConnectionContext)
  if (!context) {
    throw new Error(`DisconnectHubConnectionContext is not initialized`)
  }
  return context
}

const onConnected = async (connection: HubConnection) => {
  await connection.invoke('ConnectAsPractitioner')
}

export const setupHubConnection = async (
  setHubConnection: (props: HubConnectionProps) => void
): Promise<HubConnection> => {
  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_BASE_URL}/hubs/messaging`, {
      accessTokenFactory: async () => {
        await UserService.updateToken()
        const token = UserService.getToken()
        if (!token) {
          throw new Error('authentication required')
        }
        return token
      },
    })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (ctx) => {
        const waitSec = Math.min(Math.pow(2, ctx.previousRetryCount), 60) // 1~60sec with exponential backoff
        return waitSec * 1000
      },
    })
    .build()

  connection.onreconnected(async () => {
    await onConnected(connection)
  })

  await connection.start()
  await onConnected(connection)

  setHubConnection({ connection })
  return connection
}

export const HubConnectionContextProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const [hubConnectionProps, setHubConnectionProps] =
    useState<HubConnectionProps | null>(null)

  const disconnectHubConnection = useCallback(async () => {
    if (!hubConnectionProps) {
      return
    }

    const { connection } = hubConnectionProps
    if (connection?.state === HubConnectionState.Connected) {
      await connection.invoke('DisconnectAsPractitioner')
      await connection.stop()
    }

    setHubConnectionProps(null)
  }, [hubConnectionProps])

  useEffect(() => {
    let timer: NodeJS.Timeout
    const connect = async () => {
      try {
        await Promise.race([
          setupHubConnection(setHubConnectionProps),
          new Promise((_, reject) => (timer = setTimeout(reject, 10 * 1000))), // timeout in 10 sec
        ])
      } catch (e) {
        console.error(e)
      } 
    }
    connect()

    return () => clearTimeout(timer)
  }, [])

  return (
    <HubConnectionContext.Provider value={hubConnectionProps}>
      <HubConnectionDispatchContext.Provider value={setHubConnectionProps}>
        <DisconnectHubConnectionContext.Provider
          value={disconnectHubConnection}
        >
          {children}
        </DisconnectHubConnectionContext.Provider>
      </HubConnectionDispatchContext.Provider>
    </HubConnectionContext.Provider>
  )
}
