import Keycloak from 'keycloak-js'

let url = window.location.href

if (!url.includes('#state')) {
  url = window.location.href
  localStorage.setItem('url', url)
  console.log(url);
} else {
  url = localStorage.getItem('url')
}

const extractRealm = () => {
  let newUrl = new URL(url)

  if (newUrl.hash.includes('#state')) {
    const storageRealm = localStorage.getItem('realm')
    return storageRealm
  } else if (newUrl.hash) {
    localStorage.setItem('realm', newUrl.hash.replace('#', ''))
    return newUrl.hash.replace('#', '')
  } else if (newUrl.pathname) {
    localStorage.setItem('realm', newUrl.pathname.replace('/', ''))
    return newUrl.pathname.replace('/', '')
  }
}

const _kc = new Keycloak({
  url: `${process.env.REACT_APP_AUTH_SERVER_URL}`,
  realm: extractRealm(),
  clientId: `${process.env.REACT_APP_AUTH_RESOURCE}`,
})

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
      redirectUri: window.location.origin + '/' + localStorage.getItem('realm'),
    })
    .then((authenticated) => {
      if (!authenticated) {
        doLogin()
      }
      onAuthenticatedCallback()
    })
    .catch(console.error)
}

const doLogin = _kc.login

const doLogout = () =>
  _kc.logout({
    redirectUri: localStorage.getItem('url'),
  })

const getToken = () => _kc.token

const isLoggedIn = () => !!_kc.token

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin)

const getUsername = () => _kc.tokenParsed?.preferred_username

const getName = () => _kc.tokenParsed?.name

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role))

const getRoles = () => _kc.realmAccess.roles

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getName,
  hasRole,
  getRoles,
  extractRealm,
}

export default UserService
