import { ITermTreeNode } from 'features/reportDetails/interfaces/IReportDetails'
import { generateSentenceForComponent } from 'features/reportDetails/utils/sentenceGeneration'
import {
  ZipModuleFeaturesReportsResponsesComponentDto,
  ZipModuleFeaturesReportsResponsesImageDto,
} from 'services/zipmodule.gen'

export interface IContainerItem {
  title: string | undefined
  text: string
  id: string
  dropdownIds: string[] | null | undefined
  images?: ZipModuleFeaturesReportsResponsesImageDto[] | null
}

export interface IContainerGroup {
  [key: string]: Array<IContainerItem>
}
const collator = new Intl.Collator([], { numeric: true })

export function findingByContainers(
  components:
    | ZipModuleFeaturesReportsResponsesComponentDto[]
    | null
    | undefined,
  terminologyTree: ITermTreeNode[] | null,
  tKey: string,
  showNoContainer: boolean,
  showLevel1InSentece: boolean,
  showContainerNumberInSentence: boolean,
  showText: boolean
) {
  const containers: IContainerGroup = {}

  const observation = terminologyTree?.find(
    (el) => el.tKey?.toLowerCase() == tKey?.toLowerCase()
  )

  components?.forEach(
    (component: ZipModuleFeaturesReportsResponsesComponentDto) => {
      const level1Id = component.dropdownIds
        ? component.dropdownIds[0].split(',')[0]
        : null

      const level1 = observation?.children?.find(
        (el: ITermTreeNode) => el.id == level1Id
      )
      // we are grouping only polpys and general biopsies
      if (
        ['polyp', 'general-biopsy'].includes(
          level1?.tKey ? level1?.tKey?.toLowerCase() : ''
        )
      ) {
        const containerLevel2 = level1?.children?.find(
          (el: ITermTreeNode) => el.tKey?.toLowerCase() == 'container-number'
        )
        const noContainerLevel3 = containerLevel2?.children.find(
          (el) => el.tKey?.toLowerCase() == 'no-container'
        )
        const renderText = generateSentenceForComponent(
          showLevel1InSentece,
          showContainerNumberInSentence,
          observation,
          component.dropdownIds
        )
        component.dropdownIds?.forEach((level2Value) => {
          if (level2Value?.split(',')[0] == containerLevel2?.id) {
            const container = level2Value.split(',')
            // if no container assigned (on findings), assing no container id
            if (container.length == 1 && noContainerLevel3?.id) {
              container.push(noContainerLevel3?.id)
            }
            // if container is selected
            // and selected container is not "No Container"
            // const showNoContainerGroup = showNoContainer &&
            if (
              container.length > 0 &&
              (container[1] !== noContainerLevel3?.id || showNoContainer)
            ) {
              const containerNumber = containerLevel2?.children?.find(
                (cont: ITermTreeNode) => cont.id == container[1]
              )?.value

              if (containerNumber && containers[containerNumber]) {
                containers[containerNumber] = [
                  ...containers[containerNumber],
                  {
                    title: level1?.type,
                    text: showText ? renderText : '',
                    id: component.dropdownIds ? component.dropdownIds[0] : '',
                    dropdownIds: component.dropdownIds,
                    images: component.images,
                  },
                ]
              } else if (containerNumber) {
                containers[containerNumber] = [
                  {
                    title: level1?.type,
                    text: showText ? renderText : '',
                    id: component.dropdownIds ? component.dropdownIds[0] : '',
                    dropdownIds: component.dropdownIds,
                    images: component.images,
                  },
                ]
              }
            }
          }
        })
      }
    }
  )
  const sortedContainerKeys = Object.keys(containers).sort((a, b) =>
    collator.compare(a, b)
  )
  const sortedContainer: IContainerGroup = {}
  sortedContainerKeys.forEach((key) => {
    sortedContainer[key] = containers[key]
  })

  return sortedContainer
}
